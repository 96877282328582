/* eslint-disable react/prop-types */
import { datePickerDefaultProps } from '@material-ui/pickers/constants/prop-types';
import React from 'react';
import Intercom from 'react-intercom';

export default function IntercomComponent(props) {

  const user = {
    user_id: props.id,
    email: props.email,
    name: props.name
  };
  const [value, setValue] = React.useState("");
  const [visible, setVisible] = React.useState(false);
  React.useEffect(() => {
    setValue(props.authKey);
    setVisible(props.chatvisible);
  },[props.chatvisible])

  if (visible)
  {
    return (
      <Intercom appID={value} {...user} />
    )
  }else
  {
    return ("")
  }
}