import React from "react";
import history from "./utils/history";

import ContextState from "./context_state_config";
import { primaryColor } from "assets/jss/xrs.js";
import ReactGA from "react-ga";
import * as URLS from "./utils/XRSAPIs";

import { Provider } from "react-redux";
import { store } from "./store/redux/createReduxStore";

const App = () => {
  const [trackingCode, settrackingCode] = React.useState(null);
  const [trackingCodeInit, settrackingCodeInit] = React.useState(false);

  React.useEffect(() => {
    document.body.style.backgroundColor = primaryColor[14];

    fetch(URLS.GetSiteDetatilsAPIUrl, {
      method: "GET",
    })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          return res.json();
        } else {
          res.json().then((data) => {
            // ApiErrorHandler("Get Site Detatils", data, context);
          });
          throw Error("");
        }
      })
      .then((response) => {
        settrackingCode(response.trackingcode);
      })
      .catch((error) => {});
  }, []);

  React.useEffect(() => {
    if (trackingCode) {
      ReactGA.initialize(trackingCode);
      settrackingCodeInit(true);
    }
  }, [trackingCode]);

  React.useEffect(() => {
    if (trackingCodeInit) {
      const unlisten = history.listen((location) => {
        ReactGA.pageview(location.pathname);
      });
      return () => {
        unlisten();
      };
    }
  }, [history]);

  return (
    <Provider store={store}>
      <ContextState />
    </Provider>
  );
};

export default App;
