import imagesStyles from "../../imagesStyles";
import { primaryColor } from "assets/jss/xrs.js";

const viewAccountSectionStyle = {
  ...imagesStyles,
  white: {
    color: "white",
    fontWeight: 500,
    paddingLeft: 60,
  },
  whiteRight: {
    color: "white",
    fontWeight: 500,
    textAlign: "right",
  },
  blueTitle: {
    color: primaryColor[20],
    fontWeight: 400,
    marginBottom: 0,
  },
  RightText: {
    marginBottom: 5,
  },
  Title: {
    fontWeight: 500,
    paddingTop: 30,
  },
  Wellcome: {
    fontWeight: 500,
  },
  tableWrapper: {
    maxHeight: 270,
    overflow: "auto",
  },
  tableWrapperMobile: {
    overflowX: "hidden",
    overflowY: "scroll",
    maxHeight: 270,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 10,
  },
  fullRow: {
    width: "100%",
  },
  accountRow: {
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 0,
  },

  groupRow: {
    backgroundColor: "#D3F3F3",
    width: "100%",
    marginLeft: 0,
    marginRight: 0,
    borderBottom: "1px solid",
    borderColor: "gray",
  },
  totalRow: {
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 15,
    fontWeight: 600,
  },
};

export default viewAccountSectionStyle;
