import React, { useContext } from "react";
import classNames from "classnames";
import { primaryColor } from "assets/jss/xrs.js";
import { makeStyles } from "@material-ui/core/styles";
import landingPageStyle from "assets/jss/xrs/views/landingPageStyle.js";
import HeaderSectionLoggedID from "../SectionsPage/Sections/HeaderSectionLoggedID.js";
import WellcomeSection from "../SectionsPage/Sections/WellcomeSection.js";
import Context from "../../utils/context";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import clsx from "clsx";
import * as URLS from "../../utils/XRSAPIs";
import { isMobile } from "react-device-detect";
const useStyles = makeStyles(landingPageStyle);
let drawerWidth = 150;
if (isMobile) {
  drawerWidth = 0;
}
const useStyles2 = makeStyles((theme) => ({
  content: {
    paddingTop: 60,
    paddingBottom: 38,
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
}));

export default function LandingPage(props) {
  const context = useContext(Context);

  const classes = useStyles();
  const classes2 = useStyles2();
  const [page, setPage] = React.useState(1);

  const token = sessionStorage.getItem("access_token");

  return (
    <div style={{ backgroundColor: primaryColor[14] }}>
      <HeaderSectionLoggedID
        page="welcome"
        title="Welcome"
      ></HeaderSectionLoggedID>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <main
            className={clsx(classes2.content, {
              [classes2.contentShift]: context.stateDrawer,
            })}
          >
            <WellcomeSection></WellcomeSection>
          </main>
        </GridItem>
      </GridContainer>
    </div>
  );
}
