import React, { useContext } from "react";
import Context from "./context";
import * as ACTIONS from "../store/actions/actions";
import { useSelector, useDispatch } from "react-redux";
export const ApiErrorHandler = (from, error, context) => {
  let message = "";
  if (error.message != undefined) {
    message = error.message;
  }
  if (error.MESSAGE != undefined) {
    message = error.MESSAGE;
  }

  if (error.statusText != undefined) {
    message = error.statusText;
  }

  if (error.responseText != undefined) {
    let parsedError = JSON.parse(error.responseText);
    if (parsedError.message != undefined) {
      message = parsedError.message;
    }

    if (parsedError.error_description != undefined) {
      message = parsedError.error_description;
    }
    if (parsedError.errors != undefined) {
      var errors = parsedError.errors;
      message = "";
      errors.forEach((element) => (message += element + "\n"));
    }
  }

  switch (error.status) {
    case 401 || 403:
      message =
        "You have been logged out of this session. Please close and restart the browser to log in again";
      context.stateInfoDialog(from, message, "/landing-page");
      break;

    default:
      context.stateInfoDialog(from, message);
      break;
  }
};
