import { DOCUMENTS_ACTION_TYPES } from "../redux-actions/redux_action_types";

export const initialState = {
  open: false,
  documentToBeViewed: "",
};

export const DocumentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case DOCUMENTS_ACTION_TYPES.OPEN:
      return {
        ...state,
        open: action.payload,
      };
    case DOCUMENTS_ACTION_TYPES.DOCUMENT_TO_BE_VIEWED:
      return {
        ...state,
        documentToBeViewed: action.payload,
      };

    default:
      return state;
  }
};
