import React from "react";
import styles from "assets/jss/xrs/components/fabStyle.js";
import { makeStyles } from "@material-ui/core/styles";

import CameraAltIcon from "@material-ui/icons/CameraAlt";
import {
  cameraActions,
  documentsActions,
} from "../../store/redux/redux-actions/redux_actions";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(styles);
export default function FabViewDocument(props) {
  const classes = useStyles();

  const dispatch = useDispatch();
  function closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
  }
  if (props.viewDocument !== "") {
    return (
      <div
        onClick={() => {
          dispatch(documentsActions.viewDocumet(""));
          closeFullscreen();
        }}
        className={classes.fabContainer}
      >
        <div className={classes.fab + " " + classes.fabIconHolder}>
          <i>
            <CloseIcon></CloseIcon>
          </i>
        </div>
      </div>
    );
  } else return null;
}
