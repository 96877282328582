import { Link } from "react-router-dom";
import {
  primaryDarkColor,
  secondaryColor,
  blackColor,
  hexToRgb,
  primaryColor,
} from "assets/jss/xrs.js";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import MailIcon from "@material-ui/icons/Mail";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Icon from "@material-ui/core/Icon";
import Button from "components/CustomButtons/Button.js";
import React, { useContext, useRef } from "react";
import Context from "../../utils/context";
import AccountItem from "./AccountItem.js";
import ChangePasswordAndPin from "../../views/ChangePassworOrPin/ChangePasswordOrPin.js";
import AccountCircle from "@material-ui/icons/AccountBox";

import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomTextField from "components/CustomInput/CustomInput";
import { Collapse } from "@material-ui/core";
import landingPageStyle from "assets/jss/xrs/views/landingPageStyle.js";
import { useFormik, Formik } from "formik";
import history from "../../utils/history";
import IntercomComponent from "./IntercomComponent.js";
import classNames from "classnames";
import * as URLS from "../../utils/XRSAPIs";
import { useBeforeunload } from "react-beforeunload";
import * as ACTIONS from "../../store/actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { ApiErrorHandler } from "utils/apiErrorHandler";
import LinearProgress from "@material-ui/core/LinearProgress";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { getSiteDetailsActions } from "store/redux/redux-actions/redux_actions";

const drawerWidth = 150;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  "@keyframes burgerTransition": {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(3),
    from: {
      color: primaryDarkColor[0],
      width: 50,
      height: 50,
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(primaryDarkColor[0]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(primaryDarkColor[0]) +
        ", 0.2)",
    },
    to: { color: "black", width: 22, height: 22, boxShadow: "none" },
  },
  menuButton: {
    animationName: "$burgerTransition",
    animationDuration: "1s",
    animationDelay: "0s",
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(3),
  },
  menuLogo: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(3),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    border: "none",
  },
  DisplayY: {
    textAlign: "center",
    fontSize: 13,
    fontWeight: 400,
    marginTop: 1,
  },
  DisplayN: {
    textAlign: "center",
    fontSize: 13,
    fontWeight: 400,
    marginTop: 1,
    display: "none",
  },
  drawerPass: {
    width: drawerWidth,
    flexShrink: 0,
    border: "none",
  },

  standardButton: {
    backgroundColor: primaryColor[7],
    color: "black",
    margin: "8px 0",
    border: "none",
    cursor: "pointer",
    opacity: "1",
    borderRadius: 0,
    width: 10,
    zIndex: 999999,
  },
  accountButton: {
    cursor: "pointer",
    opacity: "1",
    borderRadius: 0,
    zIndex: 999999,
  },
  notificationButton: {
    cursor: "pointer",
    opacity: "1",
    borderRadius: 0,
    width: 50,
    zIndex: 999999,
  },
  drawerPaper: {
    height: "-webkit-fill-available",
    zIndex: 1,
    width: drawerWidth,
    top: 56,
    border: 0,
    paddingTop: 30,
    backgroundColor: primaryColor[7],
  },
  linkButton: {
    padding: "25px 25px",
    width: 35,
    textAlign: "center",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  title: {
    textAlign: "center",
    fontSize: 19,
    fontWeight: 400,
    marginTop: 40,
    color: "white",
  },
  textCenter: {
    textAlign: "center",
    fontSize: 13,
    fontWeight: 400,
    marginTop: 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),

    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  username: {
    fontSize: 14,
    width: "fit-content",
    textTransform: "capitalize",
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  Right: {
    marginLeft: "auto",
    marginRight: 20,
    textAlign: "right",
  },
}));

const useStyles2 = makeStyles(landingPageStyle);
export default function HeaderLoggedIN(props) {
  const classes = useStyles();
  const classes2 = useStyles2();

  const drawers = useSelector((state) => state.GetSiteDetailsReducer.drawers);

  // const [drawers, setDrawers] = React.useState({
  //   open: false,
  //   openSecond: false,
  //   openThird: false,
  //   lastOpenedDrawer: null, // add a new state variable to store the last opened drawer
  // });

  const updateDrawers = (values) => {
    dispatch(getSiteDetailsActions.setDrawerState(values));
    // setDrawers((prevState) => ({ ...prevState, ...values }));
  };

  const [accounts, setAccounts] = React.useState([]);
  const dispatch = useDispatch();
  const logo = useSelector((state) => state.GetSiteDetailsReducer.logoimageurl);
  const loading = useSelector((state) => state.ApiCallsReducer.loading);
  const [menuAnimation, setMenuAnimation] = React.useState(false);
  React.useEffect(() => {
    setMenuAnimation(true);
  }, []);

  const stateAccounts = useSelector((state) => state.AccountsReducer.accounts);

  const stateSelectedAccount = useSelector(
    (state) => state.SelectedAccountsReducer.account
  );
  const stateCustomer = useSelector((state) => state.CustomerReducer.customer);
  const stateMenu = useSelector((state) => state.MenuReducer.menu);
  const numOfAccounts = useSelector(
    (state) => state.AccountsReducer.numOfAccounts
  );
  const [buttons, setButtons] = React.useState([]);
  const page = props.page;

  useBeforeunload(() => {
    handleLogOut();
  });
  const ref = useRef();
  useOnClickOutside(ref, () => {
    resetDrawerState();
  });

  React.useEffect(() => {
    if (stateMenu != null && stateMenu != "no items") {
      var tmpbuttons = stateMenu.accountMenus.find(
        (x) => x.recNo == stateSelectedAccount.recno
      );
      if (tmpbuttons != null) {
        setButtons(tmpbuttons.menuButtons);
      } else {
        setButtons([]);
      }
    }
  }, [stateSelectedAccount]);

  React.useEffect(() => {
    if (stateSelectedAccount == "NoAccount") {
      fetch(URLS.GetWelcomeDetails, {
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      })
        .then((res) => {
          if (res.status == 200 || res.status == 201) {
            return res.json();
          } else {
            ApiErrorHandler("Get Welcome Details", res, context);
            throw Error("");
          }
        })
        .then((data) => {
          if (data.activeAccounts.length == 1) {
            if (data.activeAccounts[0].planinplace == "Y") {
              setPlanText("Amend");
            } else {
              setPlanText("Create");
            }
          }
        })

        .catch((error) => {
          if (error.message == "") {
          } else {
            ApiErrorHandler("Get Welcome Details", error, context);
          }
        });
    } else {
      if (stateSelectedAccount.planinplace == "Y") {
        setPlanText("Amend");
      } else {
        setPlanText("Create");
      }
    }
  }, []);

  React.useEffect(() => {
    let contextDrawers = {
      open: false,
      openSecond: false,
      openThird: false,
      lastOpenedDrawer: "drawerOne", // set the last opened drawer to drawerOne when opening
    };
    // if (isMobile) {
    // } else {
    if (!context.stateTwo) {
      contextDrawers.openSecond = false;
    } else {
      contextDrawers.openSecond = context.stateTwo;
      contextDrawers.lastOpenedDrawer = "drawerTwo";
    }
    if (!context.stateThree) {
      contextDrawers.openThird = false;
    } else {
      contextDrawers.openThird = context.stateThree;
      contextDrawers.lastOpenedDrawer = "drawerTree";
    }
    if (!context.stateOne) {
      contextDrawers.open = true;
    } else {
      contextDrawers.open = context.stateOne;
      contextDrawers.lastOpenedDrawer = "drawerOne";
    }
    // }
    updateDrawers(contextDrawers);
    var arr = [];

    if (stateAccounts == "NoAccount") {
      Object.keys(stateAccounts).forEach(function(key) {
        arr.push(stateAccounts[key]);
      });
      setAccounts(arr);
    } else {
      arr = Object.values(stateAccounts);
      setAccounts(arr);
    }
  }, []);
  const resetDrawerState = () => {
    updateDrawers({
      open: false,
      openSecond: false,
      openThird: false,
      lastOpenedDrawer: drawers.lastOpenedDrawer, // set the last opened drawer to drawerOne when opening
    });
    context.dispatchDrawer(false);

    context.dispatchDrawerTwo(false);
    context.dispatchDrawerThree(false);
    context.dispatchDrawerOne(false);
  };
  const handleDrawerOpen = () => {
    if (drawers.open || drawers.openSecond || drawers.openThird) {
      resetDrawerState();
    } else {
      context.dispatchDrawer(true);
      debugger;
      if (drawers.lastOpenedDrawer === "drawerOne" || numOfAccounts == 1) {
        handleFirtstDrawerOpen();
      } else if (drawers.lastOpenedDrawer === "drawerTwo") {
        handleSecondDrawerOpen();
      } else if (drawers.lastOpenedDrawer === "drawerTree") {
        handleThirdDrawerOpen();
      }
    }
  };
  const handleFirtstDrawerOpen = (fromBackButton = false) => {
    debugger;
    if (numOfAccounts > 1) {
      dispatch(ACTIONS.set_selectedAccount("NoAccount"));
    } else {
      dispatch(ACTIONS.set_selectedAccount(accounts[0]));
    }

    updateDrawers({
      open: true,
      openSecond: false,
      openThird: false,
      lastOpenedDrawer: "drawerOne", // set the last opened drawer to drawerOne when opening
    });
    context.dispatchDrawer(true);
    context.dispatchDrawerTwo(false);
    context.dispatchDrawerThree(false);
    context.dispatchDrawerOne(true);
    if (numOfAccounts > 1 && fromBackButton) {
      history.push(`/welcome`);
    }
  };
  const handleSecondDrawerOpen = () => {
    var arr = Object.values(stateAccounts);
    setAccounts(arr);

    updateDrawers({
      open: false,
      openSecond: true,
      openThird: false,
      lastOpenedDrawer: "drawerTwo", // set the last opened drawer to drawerTwo when opening
    });
    context.dispatchDrawer(true);

    context.dispatchDrawerTwo(true);
    context.dispatchDrawerThree(false);
    context.dispatchDrawerOne(false);
    if (stateSelectedAccount == "NoAccount") {
      dispatch(ACTIONS.set_selectedAccount(accounts[0]));

      history.push(`/viewAccount`);
    }
  };
  const handleThirdDrawerOpen = (e) => {
    updateDrawers({
      open: false,
      openSecond: false,
      openThird: true,
      lastOpenedDrawer: "drawerTree", // set the last opened drawer to drawerTwo when opening
    });
    context.dispatchDrawer(true);

    context.dispatchDrawerTwo(false);
    context.dispatchDrawerThree(true);
    context.dispatchDrawerOne(false);
  };

  const handlebacktosecond = () => {
    updateDrawers({
      openThird: false,
      openSecond: true,
      open: false,
      lastOpenedDrawer: "drawerTwo", // set the last opened drawer to drawerTwo when going back
    });
    context.dispatchDrawerTwo(true);
    context.dispatchDrawerThree(false);
    context.dispatchDrawerOne(false);
  };

  const handleLogOut = () => {
    context.authObj.logout();
  };

  const token = sessionStorage.getItem("access_token");

  const handleChangePassword = () => {
    history.push("/changePassword");
  };
  const handleChangePin = () => {
    history.push("/changePin");
  };
  const handlereadMessages = () => {
    history.push("/messages");
  };

  const context = useContext(Context);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [planText, setPlanText] = React.useState("");
  const openp = Boolean(anchorEl);
  const budgetPageButton = buttons.find((x) => x.buttonName === "budgetPage");

  const budgetPageButtonisVisible =
    budgetPageButton && budgetPageButton.buttonVisible === "Y";

  const budgetPageButtonclassName = budgetPageButtonisVisible
    ? classes.DisplayY
    : classes.DisplayN;

  if (numOfAccounts == 1) {
    return (
      <div
        ref={ref}
        style={{ padding: "0px", paddingBottom: "20px" }}
        className={classes.root}
      >
        <CssBaseline />

        <AppBar
          elevation={0}
          position="fixed"
          className={classes2.headerLoggedInColor}
        >
          {loading == true ? <LinearProgress /> : null}

          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={classes.standardButton}
              edge="end"
              height="40"
            >
              <MenuIcon
                style={{ marginRight: 5 }}
                className={
                  isMobile
                    ? menuAnimation
                      ? classes.menuButton
                      : classes.menuLogo
                    : classes.menuLogo
                }
              />
            </IconButton>

            {logo === "" ? null : (
              <img
                className={clsx(classes.menuLogo)}
                src={logo}
                alt="..."
                height="70"
              />
            )}

            <section className={classes.Right}>
              {stateCustomer.unreadmessages == 0 ? null : (
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  style={{ color: primaryColor[20] }}
                  onClick={handlereadMessages}
                  className={classes.notificationButton}
                >
                  <Badge
                    badgeContent={stateCustomer.unreadmessages}
                    style={{ color: primaryColor[20] }}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <MailIcon />
                  </Badge>
                </IconButton>
              )}

              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                style={{ color: primaryColor[20] }}
                onClick={handleMenu}
                className={classes.accountButton}
              >
                <Badge
                  style={{ color: primaryColor[20] }}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <AccountCircle />
                </Badge>
                <span className={classes.username}>
                  {" "}
                  {stateCustomer.cufname
                    ? stateCustomer.cufname
                    : stateCustomer.culname.toLowerCase()}
                </span>
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={openp}
                onClose={handleClose}
              >
                {/* <MenuItem onClick={handlereadMessages}>
                  Messages ({stateCustomer.unreadmessages}){" "}
                </MenuItem> */}
                <MenuItem onClick={handleLogOut}>Log Out</MenuItem>
                <MenuItem onClick={handleChangePassword}>
                  Change Password
                </MenuItem>
                <MenuItem onClick={handleChangePin}>Change Pin</MenuItem>
              </Menu>
            </section>
          </Toolbar>
        </AppBar>

        <Drawer
          id="Drawer"
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={drawers.open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.textCenter}>
            <Button
              component={Link}
              to="/welcome"
              className={classes.linkButton}
              color={page == "welcome" ? "primary" : "linkedin"}
            >
              <Icon className="fa fa-handshake-o" />
            </Button>
          </div>

          <h4 className={classes.textCenter}>Welcome</h4>
          {buttons.length === 0 ? null : (
            <>
              {" "}
              {renderButton(
                "viewOffer",
                "/viewoffer",
                "fa fa-suitcase",
                buttons,
                classes,
                page
              )}
              {renderButton(
                "createPayment",
                "/createPayment",
                "fa fa-credit-card",
                buttons,
                classes,
                page
              )}
              {renderButton(
                "payNow",
                "/payNow",
                "fa fa-credit-card-alt",
                buttons,
                classes,
                page
              )}
              {renderButton(
                "viewAccount",
                "/viewAccount",
                "fa fa-user-circle-o",
                buttons,
                classes,
                page
              )}
              {renderButton(
                "budgetPage",
                "/budgetPage",
                "fa fa-handshake-o",
                buttons,
                classes,
                page
              )}
              {renderButton(
                "faq",
                "/faq",
                "fa fa-question-circle-o",
                buttons,
                classes,
                page
              )}
              {renderButton(
                "requestcallback-page",
                "/requestcallback-page",
                "fa fa-phone",
                buttons,
                classes,
                page
              )}
            </>
          )}
        </Drawer>
      </div>
    );
  } else if (numOfAccounts > 1) {
    return (
      <div style={{ padding: "0px" }} className={classes.root}>
        <CssBaseline />
        <AppBar
          elevation={0}
          position="fixed"
          className={classes2.headerLoggedInColor}
        >
          {loading == true ? <LinearProgress /> : null}
          <Toolbar>
            <div width="150">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={classes.standardButton}
              >
                <MenuIcon
                  className={
                    isMobile
                      ? menuAnimation
                        ? classes.menuButton
                        : classes.menuLogo
                      : classes.menuLogo
                  }
                />
              </IconButton>
            </div>

            {logo === "" ? null : (
              <img
                className={clsx(classes.menuLogo)}
                src={logo}
                alt="..."
                height="70"
              />
            )}
            <section className={classes.Right}>
              {stateCustomer.unreadmessages == 0 ? null : (
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  style={{ color: primaryColor[20] }}
                  onClick={handlereadMessages}
                  className={classes.notificationButton}
                >
                  <Badge
                    badgeContent={stateCustomer.unreadmessages}
                    style={{ color: primaryColor[20] }}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <MailIcon />
                  </Badge>
                </IconButton>
              )}
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="primary"
                onClick={handleMenu}
                className={classes.accountButton}
              >
                <AccountCircle />
                <span className={classes.username}>
                  {" "}
                  {stateCustomer.cufname
                    ? stateCustomer.cufname
                    : stateCustomer.culname.toLowerCase()}
                </span>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={openp}
                onClose={handleClose}
              >
                <MenuItem onClick={handleLogOut}>Log Out</MenuItem>
                <MenuItem onClick={handleChangePassword}>
                  Change Password
                </MenuItem>
                <MenuItem onClick={handleChangePin}>Change Pin</MenuItem>
              </Menu>
            </section>
          </Toolbar>
        </AppBar>

        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={drawers.open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.textCenter}>
            <Button
              component={Link}
              to="/welcome"
              className={classes.linkButton}
              color={page == "welcome" ? "primary" : "linkedin"}
            >
              <Icon className="fa fa-handshake-o" />
            </Button>
          </div>

          <h4 className={classes.textCenter}>Welcome</h4>

          <div className={classes.textCenter}>
            <Button
              onClick={handleSecondDrawerOpen}
              className={classes.linkButton}
              color={page == "accounts" ? "primary" : "linkedin"}
            >
              <Icon className="fa fa-users" />
            </Button>
          </div>

          <h4 className={classes.textCenter}>Accounts ({numOfAccounts})</h4>

          <div className={budgetPageButtonclassName}>
            <Button
              component={Link}
              to="/budgetPage"
              className={classes.linkButton}
              color={page == "budgetPage" ? "primary" : ""}
            >
              <Icon className="fa fa-handshake-o" />
            </Button>
            <h4 className={classes.textCenter}>Buget Planner</h4>
          </div>

          <div className={classes.textCenter}>
            <Button
              className={classes.linkButton}
              component={Link}
              to="/faq"
              color={page == "Faq" ? "primary" : "linkedin"}
            >
              <Icon className="fa fa-question-circle-o" />
            </Button>
          </div>
          <h4 className={classes.textCenter}>Faq</h4>
        </Drawer>
        <Drawer
          key="drawer3"
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={drawers.openSecond}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {accounts.map((account, index) => (
            <AccountItem
              key={index}
              onClick={handleThirdDrawerOpen}
              account={account}
            />
          ))}

          <div className={classes.textCenter}>
            <Button
              onClick={() => handleFirtstDrawerOpen(true)}
              className={classes.linkButton}
              color={page == "back" ? "primary" : "linkedin"}
            >
              <Icon className="fa fa-chevron-left" />
            </Button>
          </div>

          <h4 className={classes.textCenter}>back</h4>
        </Drawer>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={drawers.openThird}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {buttons.length === 0 ? null : (
            <>
              {" "}
              {renderButton(
                "viewOffer",
                "/viewoffer",
                "fa fa-suitcase",
                buttons,
                classes,
                page
              )}
              {renderButton(
                "createPayment",
                "/createPayment",
                "fa fa-credit-card",
                buttons,
                classes,
                page
              )}
              {renderButton(
                "payNow",
                "/payNow",
                "fa fa-credit-card-alt",
                buttons,
                classes,
                page
              )}
              {renderButton(
                "viewAccount",
                "/viewAccount",
                "fa fa-user-circle-o",
                buttons,
                classes,
                page
              )}
              {renderButton(
                "budgetPage",
                "/budgetPage",
                "fa fa-handshake-o",
                buttons,
                classes,
                page
              )}
              {renderButton(
                "faq",
                "/faq",
                "fa fa-question-circle-o",
                buttons,
                classes,
                page
              )}
              {renderButton(
                "requestcallback-page",
                "/requestcallback-page",
                "fa fa-phone",
                buttons,
                classes,
                page
              )}
            </>
          )}

          <div className={classes.textCenter}>
            <Button
              onClick={handlebacktosecond}
              className={classes.linkButton}
              color={page == "back" ? "primary" : "linkedin"}
            >
              <Icon className="fa fa-chevron-left" />
            </Button>
          </div>
          <h4 className={classes.textCenter}>back</h4>
        </Drawer>
      </div>
    );
  } else if (numOfAccounts == 0) {
    return "loading...";
  }
}

function useOnClickOutside(ref, handler) {
  React.useEffect(() => {
    if (isMobile) {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };

      // Add ref and handler to effect dependencies
      // It's worth noting that because passed in handler is a new ...
      // ... function on every render that will cause this effect ...
      // ... callback/cleanup to run every render. It's not a big deal ...
      // ... but to optimize you can wrap handler in useCallback before ...
      // ... passing it into this hook.
    }
  }, [ref, handler]);
}

function renderButton(name, path, icon, buttons, classes, page) {
  const button = buttons.find((x) => x.buttonName === name);
  // console.log(button.buttonName);
  // console.log(button.buttonVisible);

  if (!button || button.buttonVisible !== "Y") {
    return null;
  }
  return (
    <>
      <div className={classes[textClass(button)]}>
        <Button
          component={Link}
          to={path}
          className={classes.linkButton}
          color={page === button.buttonName ? "primary" : "linkedin"}
        >
          <Icon className={icon} />
        </Button>
      </div>
      <h4 className={classes[textClass(button)]}>{button.buttonText}</h4>
    </>
  );
}

function textClass(button) {
  return button.buttonVisible === "Y" ? "DisplayY" : "DisplayN";
}
