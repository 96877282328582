import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Hidden from "@material-ui/core/Hidden";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useDispatch, useSelector } from "react-redux";
import Input from "@material-ui/core/Input";
// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";

// core components

import styles from "assets/jss/xrs/components/customInputStyle.js";

const useStyles = makeStyles(styles);

export default function CustomInput(props) {
  const currency = useSelector((state) => state.GetSiteDetailsReducer.currency);

  const {
    formControlProps,
    id,
    helperText,
    label,
    labelProps,
    inputProps,
    error,
    white,
    showCurrency,
    inputRootCustomClasses,
    success,
    multiline,
    reg,
  } = props;
  const classes = useStyles();
  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error,
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white,
  });
  const rootClasses = classNames({
    [classes.fieldSet]: true,
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
  });

  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }
  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      {inputProps.placeholder !== undefined || label !== undefined ? (
        <InputLabel
          shrink={true}
          className={
            white === true
              ? classes.labelRootWhite
              : classes.labelRoot + " " + labelClasses
          }
          htmlFor={id}
          
          {...labelProps}
        >
          <Hidden mdUp>
            {label !== undefined ? label : inputProps.placeholder}
          </Hidden>
          <Hidden smDown>
            {inputProps.placeholder !== undefined
              ? inputProps.placeholder
              : label !== undefined
              ? label
              : null}
          </Hidden>
        </InputLabel>
      ) : null}
      <Input
        classes={{
          input: inputClasses,
          root: rootClasses,
          disabled: classes.disabled,
          underline: underlineClasses,
        }}
        id={id}
        multiline={multiline}
        rows={multiline ? 3 : 1}
        startAdornment={
          showCurrency ? (
            <InputAdornment
              classes={{ root: classes.currency }}
              position="start"
            >
              {currency}
            </InputAdornment>
          ) : (
            false
          )
        }
        mind="ters"
        variant="outlined"
        ref={reg}
        inputProps={{ ...inputProps }}
      />
      {helperText !== undefined ? (
        <Hidden mdUp>
          <FormHelperText
            className={classes.helperTextRoot}
            id="name-error-text"
          >
            {helperText}
          </FormHelperText>
        </Hidden>
      ) : null}
      {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  );
}

CustomInput.propTypes = {
  label: PropTypes.string,
  helperText: PropTypes.string,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  showCurrency: PropTypes.bool,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  reg: PropTypes.object,
  multiline: PropTypes.bool,
};
