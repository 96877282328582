import imagesStyles from "../../imagesStyles";
import { primaryColor } from "assets/jss/xrs.js";

const viewOfferSectionsStyle = {
  ...imagesStyles,
  white: {
    color: "white",
    fontWeight: 500,
    paddingLeft: 20,
  },
  visible: {
    display: "box",
  },
  hide: {
    display: "none",
  },
  whiteRight: {
    color: "white",
    fontWeight: 500,
    textAlign: "right",
  },
  blueTitle: {
    color: primaryColor[20],
    fontWeight: 400,
  },
  Title: {
    fontWeight: 500,
    paddingTop: 30,
  },
  Wellcome: {
    fontWeight: 500,
  },
  blue: {
    backgroundColor: "#0065a4",
    borderRadius: 0,
    color: "#ffffff",
    padding: 10,
    textAlign: "center",
    fontWeight: 500,
    fontSize: 18,
  },
  green: {
    backgroundColor: "#4caf50;",
    borderRadius: 0,
    color: "#ffffff",
    padding: 10,
    textAlign: "center",
    fontWeight: 500,
    fontSize: 18,
  },
  blueBox: {
    borderRadius: 0,
    margin: 10,
  },
  DiscountBlue: {
    color: "#0065a4",
    textAlign: "center",
    padding: 25,
    fontWeight: 500,
    fontSize: 16,
  },
  DiscountGreen: {
    color: "#4caf50",
    textAlign: "center",
    padding: 25,
    fontWeight: 500,
    fontSize: 16,
  },
  DiscountItem: {
    textAlign: "center",
    padding: 14,
    fontWeight: 500,
    margin: 1,
    fontSize: 14,
    backgroundColor: "#f2f7fa",
    Border: "none",
  },
  textButton: {
    textTransform: "none",
    width: "100%",
  },
  box: {
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: "white",
  },
};

export default viewOfferSectionsStyle;
