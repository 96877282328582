import { isMobile } from "react-device-detect";
let drawerWidth = 0;
if (isMobile) {
  drawerWidth = 0;
}

const changePasswordOrPinStyle = (theme) => ({
  root: {
    width: "100%",
    margin: 0,
    padding: 0,
  },
  button: {
    marginRight: theme.spacing(1),
    padding: 13,
  },
  content: {
    paddingBottom: 38,
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  hide: {
    display: "none",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  white: {
    color: "white",
    fontWeight: 500,
    paddingLeft: 30,
  },
  whiteInfo: {
    color: "white",
    fontWeight: 400,
    paddingLeft: 0,
    paddingTop: 10,
  },
  whiteInfoMiddle: {
    color: "white",
    fontWeight: 400,
    paddingLeft: 0,

    textAlign: "center",
  },
  whiteRight: {
    color: "white",
    fontWeight: 500,
    textAlign: "right",
  },
  blueTitle: {
    color: "#0065a4",
    fontWeight: 400,
  },
  blueText: {
    color: "#0065a4",
    fontWeight: 300,
  },
  Title: {
    fontWeight: 500,
    paddingTop: 30,
  },
  Wellcome: {
    fontWeight: 500,
  },
  BoxFull: {
    width: "100%",
    paddingLeft: 0,
    paddingRight: 0,
  },
  Middle: {
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    textAlign: "center",
    border: 0,
  },
  blueTextMiddle: {
    color: "#0065a4",
    fontWeight: 400,
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    textAlign: "center",
    border: 0,
  },

  rightValues: {
    textAlign: "right",
    fontWeight: 300,
  },
});

export default changePasswordOrPinStyle;
