import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import clsx from "clsx";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import queryString from "query-string";
import React, { useContext } from "react";
import { isMobile } from "react-device-detect";
import NumberFormat from "react-number-format";
import Context from "../../utils/context";
import * as URLS from "../../utils/XRSAPIs";
import BlueHeader from "../SectionsPage/Sections/BlueHeaderSection.js";
import HeaderSectionLoggedID from "../SectionsPage/Sections/HeaderSectionLoggedID.js";
import * as ACTIONS from "../../store/actions/actions";
import { ApiErrorHandler } from "utils/apiErrorHandler";
import ReactHtmlParser from "react-html-parser";

import {
  apiActions,
  getSiteDetailsActions,
  htmlActions,
} from "../../store/redux/redux-actions/redux_actions";
import { useDispatch, useSelector } from "react-redux";
import { Hidden } from "@material-ui/core";

let drawerWidth = 150;
if (isMobile) {
  drawerWidth = 0;
}
const useStyles2 = makeStyles((theme) => ({
  content: {
    paddingTop: 60,
    paddingBottom: 38,
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
    padding: 13,
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  white: {
    color: "white",
    fontWeight: 500,
    paddingLeft: 60,
  },
  hide: {
    display: "none",
  },
  whiteRight: {
    color: "white",
    fontWeight: 500,
    textAlign: "right",
  },
  blueTitle: {
    color: "#0065a4",
    fontWeight: 400,
  },
  cardNOBORDER: {
    border: 0,
  },
  Middle: {
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    textAlign: "center",
    border: 0,
  },
  blueText: {
    color: "#0065a4",
    fontWeight: 300,
  },
  Title: {
    fontWeight: 500,
    paddingTop: 30,
  },
  Wellcome: {
    fontWeight: 500,
  },
  BoxFull: {
    width: "100%",
  },
  rightValues: {
    textAlign: "right",
    fontWeight: 300,
  },
}));
export default function PayNowPaymentStatus(props) {
  const dispatch = useDispatch();

  const [authcode, setAuthcode] = React.useState("");
  const [message, setMessage] = React.useState("");
  //const [resultcode, setResultCode] = React.useState("");
  const [ammount, setAmmount] = React.useState("");
  const [passref, setPassref] = React.useState("0000000000");
  const currency = useSelector((state) => state.GetSiteDetailsReducer.currency);

  const [vissibleSucc, setVissibleSucc] = React.useState(false);
  const context = useContext(Context);
  const stateSelectedAccount = useSelector(
    (state) => state.SelectedAccountsReducer.account
  );
  const paymentStatusFrom = useSelector(
    (state) => state.SiteReducer.paymentStatusFrom
  );
  const [paymentstatustext, setpaymentstatustext] = React.useState("");

  const style = {
    white: {
      color: "white",
      fontWeight: 500,
      paddingLeft: 20,
    },
    visible: {
      display: "box",
    },
    hide: {
      display: "none",
    },
    whiteRight: {
      color: "white",
      fontWeight: 500,
      textAlign: "right",
    },
    blueTitle: {
      color: "#0065a4",
      fontWeight: 400,
    },
    Title: {
      textAlign: "center",
      fontWeight: 500,
      paddingTop: 30,
    },
    Wellcome: {
      fontWeight: 500,
    },
    blue: {
      backgroundColor: "#0065a4",
      borderRadius: 0,
      color: "#ffffff",
      padding: 10,
      textAlign: "center",
      fontWeight: 500,
      fontSize: 18,
    },
    green: {
      backgroundColor: "#ffffff;",
      borderRadius: 0,
      padding: 10,
      textAlign: "center",
      fontWeight: 500,
      fontSize: 19,
      paddingTop: 30,
      color: "#0065a4",
    },
    red: {
      backgroundColor: "#ffffff;",
      borderRadius: 0,
      padding: 10,
      textAlign: "center",
      fontWeight: 500,
      fontSize: 19,
      paddingTop: 30,
      color: "#ff0000",
    },
    blueBox: {
      paddingLeft: 50,
      paddingRight: 50,
      paddingBottom: 10,
      borderRadius: 4,
    },
    DiscountBlue: {
      color: "#0065a4",
      textAlign: "center",
      paddingTop: 10,
      fontWeight: 500,
      fontSize: 16,
    },
    DiscountGreen: {
      color: "#4caf50",
      textAlign: "center",
      padding: 25,
      fontWeight: 500,
      fontSize: 16,
    },
    DiscountItem: {
      textAlign: "center",
      padding: 14,
      fontWeight: 500,
      margin: 1,
      fontSize: 16,
      backgroundColor: "#e6f2e6",
      Border: "none",
      borderRadius: 4,
    },
    GreenTick: {
      color: "#4caf50",
    },
    RedTick: {
      color: "#990000",
    },

    DiscountItemRed: {
      textAlign: "center",
      padding: 14,
      fontWeight: 500,
      margin: 1,
      fontSize: 16,
      backgroundColor: "#ffe5e5",
      Border: "none",
      borderRadius: 4,
    },
    AmmountItem: {
      textAlign: "center",
      padding: 14,
      fontWeight: 500,
      margin: 1,
      fontSize: 40,
      backgroundColor: "#e6f2e6",
      Border: "none",
      Radiusradius: 4,
    },
    AmmountItemRed: {
      textAlign: "center",
      padding: 14,
      fontWeight: 500,
      margin: 1,
      fontSize: 40,
      backgroundColor: "#ffe5e5",
      Border: "none",
      Radiusradius: 4,
    },
    footer: {
      backgroundColor: "#4caf50",
      fontSize: 16,
      padding: 14,
      color: "#fff",
      borderRadius: 4,
      textAlign: "center",
    },
    footerRed: {
      backgroundColor: "#990000",
      fontSize: 16,
      padding: 14,
      color: "#fff",
      borderRadius: 4,
      textAlign: "center",
    },
    hidden: {
      display: "none",
    },
    textButton: {
      textTransform: "none",
      width: "100%",
    },
    box: {
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: "white",
    },
    middle: {
      width: "500px",
    },
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  React.useEffect(() => {
    const parsed = queryString.parse(props.location.search);

    setAmmount(parsed.AMOUNT);
    setMessage(parsed.MESSAGE);

    setAuthcode(parsed.AUTHCODE);
    // setPassref(parsed.PASSREF);
    // setResultCode(parsed.RESULTCODE);
    if (parsed.RESULTCODE == "00") {
      setVissibleSucc(true);
    } else {
      setVissibleSucc(false);
    }

    const token = sessionStorage.getItem("access_token");
    fetch(URLS.GetWelcomeDetails, {
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          return res.json();
        } else {
          res.json().then((data) => {
            ApiErrorHandler("Get Welcome Details", data, context);
          });
          throw Error("");
        }
      })
      .then(
        (data) => {
          dispatch(ACTIONS.set_Customer(data.customer));

          if (data.customer.cardastdigits == null) {
            setPassref("000000");
          } else {
            setPassref(data.customer.cardastdigits);
          }
          dispatch(htmlActions.termsQuickPay(data.customer.termsquickpay));
          dispatch(htmlActions.termsPlan(data.customer.termsplan));
          dispatch(htmlActions.termsDiscount(data.customer.termsdiscount));

          dispatch(
            htmlActions.viewOfferText(data.activeAccounts[0].viewoffertext)
          );
          dispatch(
            htmlActions.createPlanText(data.activeAccounts[0].createplantext)
          );
          dispatch(
            htmlActions.createPlantoc(data.activeAccounts[0].createplantoc)
          );
          dispatch(
            htmlActions.createBudgetText(
              data.activeAccounts[0].createbudgettext
            )
          );
          dispatch(
            htmlActions.viewAccountText(data.activeAccounts[0].viewacccounttext)
          );
          dispatch(ACTIONS.set_Accounts(data.activeAccounts));
          dispatch(ACTIONS.set_num_accounts(data.activeAccounts.length));

          dispatch(ACTIONS.set_Callbacks(data.callbackReasons));
          data.activeAccounts.map((account, index) => {
            if (stateSelectedAccount.recno == account.recno) {
              dispatch(ACTIONS.set_selectedAccount(account));
            }
          });
        },
        [0]
      )
      .catch((error) => {
        if (error.message == "") {
        } else {
          ApiErrorHandler("Get Welcome Details", error, context);
        }
      });
    fetch(URLS.GetSiteDetatilsAPIUrl, {
      method: "GET",
    })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          return res.json();
        } else {
          res.json().then((data) => {
            ApiErrorHandler("Get Site Detatils", data, context);
          });
          throw Error("");
        }
      })
      .then((response) => {
        setpaymentstatustext(response.paymentstatustext);
      });
  }, []);

  const token = sessionStorage.getItem("access_token");

  const useStyles1 = makeStyles(style);
  const classes = useStyles();
  const classes2 = useStyles2();
  const classes1 = useStyles1();

  const [termsApiText, setTermsApiText] = React.useState("");
  return (
    <div style={{ background: "#e3e8ee", margin: 0 }}>
      <main
        className={clsx(classes2.content, {
          [classes2.contentShift]: context.stateDrawer,
        })}
      >
        <HeaderSectionLoggedID
          page="paynow"
          title="Payment Status"
        ></HeaderSectionLoggedID>
        <BlueHeader></BlueHeader>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card
              style={{
                paddingLeft: "10px",
                paddingBottom: "10px",
                paddingRight: "10px",
                margin: 0,
              }}
            >
              <CardBody style={{ padding: "3px" }}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6} lg={6}>
                    <Paper
                      elevation={0}
                      className={
                        vissibleSucc ? classes1.shown : classes1.hidden
                      }
                    >
                      <Paper
                        elevation={0}
                        className={vissibleSucc ? classes1.green : classes1.red}
                      ></Paper>

                      <Paper
                        elevation={0}
                        className={vissibleSucc ? classes1.green : classes1.red}
                      >
                        <DoneOutlineIcon
                          className={classes1.GreenTick}
                          style={{ fontSize: 50 }}
                        >
                          SUCCESS{" "}
                        </DoneOutlineIcon>
                      </Paper>
                      <Paper elevation={0} className={classes1.blueBox}>
                        <p className={classes1.DiscountBlue}>
                          Thank you for your payment of:
                        </p>
                        <p className={classes1.AmmountItem}>
                          <NumberFormat
                            value={ammount}
                            displayType={"text"}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            decimalScale={2}
                            prefix={currency}
                          />
                        </p>
                        <p className={classes1.DiscountBlue}>Payment method:</p>

                        <p className={classes1.DiscountItem}>
                          Card ending in **
                          {passref != null ? passref.slice(-2) : ""}
                        </p>

                        <p className={classes1.DiscountBlue}>
                          Your Auth Code is:
                        </p>
                        <p className={classes1.DiscountItem}>{authcode}</p>

                        <p className={classes1.footer}>
                          Your account has been updated to reflect this
                          transaction.
                        </p>
                      </Paper>
                    </Paper>
                    <Paper
                      variant="outlined"
                      elevation={0}
                      className={
                        !vissibleSucc ? classes1.shown : classes1.hidden
                      }
                    >
                      <Paper
                        variant="outlined"
                        elevation={0}
                        className={classes1.green}
                      >
                        <CloseIcon
                          className={classes1.RedTick}
                          style={{ fontSize: 50 }}
                        ></CloseIcon>{" "}
                        {message}
                      </Paper>
                      <Paper elevation={0} className={classes1.blueBox}>
                        <p className={classes1.DiscountBlue}>
                          Your payment of:
                        </p>
                        <p className={classes1.AmmountItemRed}>
                          <NumberFormat
                            value={ammount}
                            displayType={"text"}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            decimalScale={2}
                            prefix={currency}
                          />
                        </p>

                        <p className={classes1.footerRed}>
                          Your payment has not been taken!.
                        </p>
                      </Paper>
                    </Paper>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6} lg={6}>
                    {paymentStatusFrom ? (
                      <Paper variant="outlined" elevation={0}>
                        <Paper
                          variant="outlined"
                          elevation={0}
                          className={classes1.green}
                        >
                          Did you know?
                        </Paper>
                        <Paper elevation={0} className={classes1.blueBox}>
                          <p className={classes1.DiscountBlue}>
                            {ReactHtmlParser(paymentstatustext)}
                          </p>
                        </Paper>
                        ``
                      </Paper>
                    ) : null}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </main>
    </div>
  );
}
