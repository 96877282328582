import { Collapse } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Link from "@material-ui/core/Link";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/core/styles";
import changePasswordOrPinStyle from "../../assets/jss/xrs/views/changePasswordOrPinStyle";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Parallax from "components/Parallax/Parallax.js";
import Button from "components/CustomButtons/Button.js";
import TextField from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import fnmoment from "moment";
import React, { useContext } from "react";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import Context from "utils/context";
import * as URLS from "utils/XRSAPIs";
import history from "utils/history";
import Slider from "react-slick";
import BlueHeader from "../SectionsPage/Sections/BlueHeaderSection.js";
import clsx from "clsx";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { ApiErrorHandler } from "utils/apiErrorHandler";
import { primaryColor } from "assets/jss/xrs";

let drawerWidth = 0;
if (isMobile) {
  drawerWidth = 0;
}

const useStyles = makeStyles(changePasswordOrPinStyle);

function getSteps() {
  return ["Instructions", "Payments", "Summary"];
}

const SliderSettings = {
  infinite: false,
  arrows: false,
  touchMove: false,

  centerMode: false,
  variableWidth: false,
  dots: false,
  initialSlide: 0,
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export default function ChangePasswordAndPin(props) {
  const context = useContext(Context);
  const classes = useStyles();
  const [skipped, setSkipped] = React.useState(new Set());
  const [topNav, setTopNav] = React.useState(null);
  const steps = getSteps();
  const [step, setStep] = React.useState(0);

  const [cReference, setReference] = React.useState("");
  const [cMobileNumber, setMobileNumber] = React.useState("");
  const [cEmail, setcEmail] = React.useState("");

  const [cDateOfBirth, setDateOfBirth] = React.useState("");
  const [CredentialToReset, setCredentialToReset] = React.useState("Password");

  const [OldPassword, setOldPassword] = React.useState("");
  const [NewPassword, setNewPassword] = React.useState("");
  const [ConfirmPassword, setConfirmPassword] = React.useState("");
  const [SecurityCode, setSecurityCode] = React.useState("");
  const stateCustomer = useSelector((state) => state.CustomerReducer.customer);
  const registrationType = useSelector(
    (state) => state.AccountsReducer.registrationMethod
  );

  const isStepOptional = (step) => {
    return step === 9;
  };
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleChange = (newValue) => {
    setStep(newValue);
  };
  function getSteps() {
    return ["Instructions", "Payments", "Summary"];
  }

  React.useEffect(() => {
    setCredentialToReset(props.cred);
    var m = stateCustomer.cumobilephone;
    setMobileNumber(m);
    var r = stateCustomer.custnumber;
    setReference(r);
    var d = stateCustomer.cudob;
    setDateOfBirth(d);
    setStep(0);
  }, []);

  const token = sessionStorage.getItem("access_token");

  const submitPinFormEvent = (event) => {
    event.preventDefault();
    if (ConfirmPassword != NewPassword) {
      context.stateInfoDialog(
        "Change pin",
        "New Pin and Confirm pin do not match!"
      );
      return;
    }
    var data;
    data = JSON.stringify({
      Reference: cReference,
      MobileNumber: cMobileNumber,
      DateOfBirth: cDateOfBirth,
      CredentialToReset: CredentialToReset,
    });

    fetch(URLS.sendCode, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: data,
    })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          return res.json();
        } else {
          res.json().then((data) => {
            ApiErrorHandler("Change Password", data, context);
          });
          throw Error("");
        }
      })
      .then((data) => {
        setStep((prevActiveStep) => prevActiveStep + 1);
        topNav.slickNext();
      }, [])

      .catch((error) => {
        if (error.message == "") {
        } else {
          ApiErrorHandler("Change Password", error, context);
        }
      });
  };

  const submitChangePassFormEvent = (event) => {
    event.preventDefault();
    if (ConfirmPassword != NewPassword) {
      context.stateInfoDialog(
        "Change password",
        "New password and confirm password do not match!"
      );
      return;
    }
    var data;
    var url =
      CredentialToReset == "password" ? URLS.changePassword : URLS.changePin;
    if (CredentialToReset == "password") {
      if (registrationType == "email") {
        var url = URLS.ChangeBusinessPassword;

        data = JSON.stringify({
          OldPassword: OldPassword,
          NewPassword: NewPassword,
          ConfirmPassword: ConfirmPassword,
          SecurityCode: SecurityCode,
          Reference: cReference,
          email: cEmail,
          CredentialToReset: "Password",
        });
      } else if (registrationType == "phone") {
        var url = URLS.ChangePersonalPassword;

        data = JSON.stringify({
          OldPassword: OldPassword,
          NewPassword: NewPassword,
          ConfirmPassword: ConfirmPassword,
          SecurityCode: SecurityCode,
          Reference: cReference,
          MobileNumber: cMobileNumber,
          CredentialToReset: "Password",
        });
      } else {
        var url = URLS.changePassword;
        data = JSON.stringify({
          OldPassword: OldPassword,
          NewPassword: NewPassword,
          ConfirmPassword: ConfirmPassword,
          SecurityCode: SecurityCode,
          Reference: cReference,
          MobileNumber: cMobileNumber,
          DateOfBirth: cDateOfBirth,
          CredentialToReset: "Password",
        });
      }
    } else {
      if (registrationType == "email") {
        var url = URLS.ChangeBusinessPIN;

        data = JSON.stringify({
          OldPIN: OldPassword,
          NewPIN: NewPassword,
          ConfirmPIN: ConfirmPassword,
          SecurityCode: SecurityCode,
          Reference: cReference,
          email: cEmail,
          CredentialToReset: "Password",
        });
      } else if (registrationType == "phone") {
        var url = URLS.ChangePersonalPIN;

        data = JSON.stringify({
          OldPIN: OldPassword,
          NewPIN: NewPassword,
          ConfirmPIN: ConfirmPassword,
          SecurityCode: SecurityCode,
          Reference: cReference,
          MobileNumber: cMobileNumber,
          CredentialToReset: "Password",
        });
      } else {
        var url = URLS.changePin;

        data = JSON.stringify({
          OldPIN: OldPassword,
          NewPIN: NewPassword,
          ConfirmPIN: ConfirmPassword,
          SecurityCode: SecurityCode,
          Reference: cReference,
          MobileNumber: cMobileNumber,
          DateOfBirth: cDateOfBirth,
          CredentialToReset: "Password",
        });
      }
    }

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: data,
    })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          return res.json();
        } else {
          res.json().then((data) => {
            ApiErrorHandler("Change Password", data, context);
          });
          throw Error("");
        }
      })
      .then((data) => {
        setStep((prevActiveStep) => prevActiveStep + 1);
        topNav.slickNext();
      }, [])
      .catch((error) => {
        if (error.message == "") {
        } else {
          ApiErrorHandler("Change Password", error, context);
        }
      });
  };

  const handleFinish = () => {};

  async function handleErrors(response) {
    // ;
    if (!response.ok) {
      var data = await response.json();
      throw Error(data.message);
    }

    if (response.status == 401) {
      context.authObj.logout();
    }

    return response;
  }
  const handleBack = () => {
    let newSkipped = skipped;
    if (isStepSkipped(step)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(step);
    }
    setStep((prevActiveStep) => prevActiveStep - 1);
    setSkipped(newSkipped);
    topNav.slickPrev();
  };

  return (
    <div>
      <BlueHeader></BlueHeader>

      <Card style={{ backgroundColor: primaryColor[19], margin: 0 }}>
        <CardBody>
          <Slider
            ref={(slider) => {
              setTopNav(slider);
            }}
            {...SliderSettings}
          >
            <div key={0} className="col ">
              <div className={step == 0 ? "step-cell active" : "step-cell"}>
                Verify User
                <div className="arrow-outer">
                  <div className="arrow-inner"></div>
                </div>
              </div>
            </div>

            <div key={1} className="col">
              <div className={step == 1 ? "step-cell active" : "step-cell"}>
                <div className="arrow-outer arrow-outer-before">
                  <div className="arrow-inner"></div>
                </div>
                Change {CredentialToReset == "password" ? "Password" : "Pin"}
                <div className="arrow-outer">
                  <div className="arrow-inner"></div>
                </div>
              </div>
            </div>

            <div key={2} className="col">
              <div className={step == 2 ? "step-cell active" : "step-cell"}>
                <div className="arrow-outer arrow-outer-before">
                  <div className="arrow-inner"></div>
                </div>
                Confirmation
                <div className="arrow-outer">
                  <div className="arrow-inner"></div>
                </div>
              </div>
            </div>
          </Slider>
          <div
            style={{ display: step == 0 ? "block" : "none" }}
            className="budget-cell d-flex flex-column"
          >
            <div className="center-container-940">
              <GridContainer alignItems="center" justify="center">
                <GridItem xs={12} sm={12} md={12}>
                  <form
                    className={classes.form}
                    name="pinForm"
                    onSubmit={submitPinFormEvent}
                  >
                    <p
                      className={classes.description + " " + classes.textCenter}
                    ></p>
                    <h4>Verify by SMS</h4>

                    <TextField
                      formControlProps={{
                        fullWidth: true,
                      }}
                      className={classes.control}
                      inputProps={{
                        name: "customernumber",
                        type: "text",
                        value: cReference,
                        placeholder: "Customer Number",
                        required: "required",
                        onChange: (e) => {
                          setReference(e.target.value);
                        },
                      }}
                    />
                    {registrationType == "phone" ? (
                      <TextField
                        formControlProps={{
                          fullWidth: true,
                        }}
                        className={classes.control}
                        inputProps={{
                          name: "phone",
                          type: "tel",
                          value: cMobileNumber,
                          required: "required",
                          placeholder: "Mobile Phone",
                          onChange: (e) => {
                            setMobileNumber(e.target.value);
                          },
                        }}
                      />
                    ) : registrationType == "email" ? (
                      <TextField
                        formControlProps={{
                          fullWidth: true,
                        }}
                        className={classes.control}
                        inputProps={{
                          name: "email",
                          type: "email",
                          value: cEmail,
                          required: "required",
                          placeholder: "Email",
                          onChange: (e) => {
                            setcEmail(e.target.value);
                          },
                        }}
                      />
                    ) : (
                      <>
                        <TextField
                          formControlProps={{
                            fullWidth: true,
                          }}
                          className={classes.control}
                          inputProps={{
                            name: "phone",
                            type: "tel",
                            value: cMobileNumber,
                            required: "required",
                            placeholder: "Mobile Phone",
                            onChange: (e) => {
                              setMobileNumber(e.target.value);
                            },
                          }}
                        />
                        <TextField
                          formControlProps={{
                            fullWidth: true,
                          }}
                          className={classes.control}
                          inputProps={{
                            name: "dob",
                            type: "date",
                            minwidth: "95%",
                            value: cDateOfBirth,
                            required: "required",
                            placeholder: "Date of birth",
                            onChange: (e) => {
                              setDateOfBirth(e.target.value);
                            },
                          }}
                        />
                      </>
                    )}

                    <div className={classes.Middle}>
                      <div>
                        <Button
                          disabled={step === 0}
                          onClick={handleBack}
                          color="primary"
                          className={classes.button}
                        >
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          className={classes.button}
                        >
                          {step === steps.length - 1 ? "Finish" : "Next"}
                        </Button>
                      </div>
                    </div>
                  </form>
                </GridItem>
              </GridContainer>
            </div>
          </div>
          <div
            style={{ display: step == 1 ? "block" : "none" }}
            className="budget-cell d-flex flex-column"
          >
            <div className="center-container-940">
              <GridContainer alignItems="center" justify="center">
                <GridItem xs={12} sm={12} md={12}>
                  <form
                    className={classes.form}
                    onSubmit={submitChangePassFormEvent}
                  >
                    <p
                      className={classes.description + " " + classes.textCenter}
                    ></p>
                    <h4>
                      A once off token has been sent to your Mobile. Please
                      provide{" "}
                      {CredentialToReset == "password" ? "password" : "pin"} and
                      once off token details below
                    </h4>

                    <TextField
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "password",
                        autoComplete: "off",
                        name: "OldPassword",
                        required: "required",
                        placeholder:
                          CredentialToReset == "password"
                            ? "Old Password"
                            : "Old Pin",
                        pattern:
                          CredentialToReset == "password"
                            ? "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9?=.*[!@#$%^&*]{8,}$"
                            : "[0-9]{6}",
                        title:
                          CredentialToReset == "password"
                            ? "Minimum of 8 Characters, contain special characters, at least 1 Uppercase Letter and 1 numerical digit"
                            : "6 digits only",
                        onChange: (e) => {
                          setOldPassword(e.target.value);
                        },
                      }}
                    />
                    <TextField
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "password",
                        autoComplete: "off",
                        name: "NewPassword",
                        placeholder:
                          CredentialToReset == "password"
                            ? "New Password"
                            : "New Pin",
                        required: "required",
                        pattern:
                          CredentialToReset == "password"
                            ? "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9?=.*[!@#$%^&*]{8,}$"
                            : "[0-9]{6}",
                        title:
                          CredentialToReset == "password"
                            ? "Minimum of 8 Characters, contain at least 1 Uppercase Letter and 1 numerical digit"
                            : "6 digits only",
                        onChange: (e) => {
                          setNewPassword(e.target.value);
                        },
                      }}
                    />
                    <TextField
                      formControlProps={{
                        fullWidth: true,
                      }}
                      className={classes.control}
                      inputProps={{
                        type: "password",
                        autoComplete: "off",
                        name: "ConfirmPassword",
                        placeholder:
                          CredentialToReset == "password"
                            ? "Confirm Password"
                            : "Confirm Pin",
                        required: "required",
                        pattern:
                          CredentialToReset == "password"
                            ? "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9?=.*[!@#$%^&*]{8,}$"
                            : "[0-9]{6}",
                        title:
                          CredentialToReset == "password"
                            ? "Minimum of 8 Characters, contain at least 1 Uppercase Letter, 1 numerical digit and special characters"
                            : "6 digits only",
                        onChange: (e) => {
                          setConfirmPassword(e.target.value);
                        },
                      }}
                    />
                    <TextField
                      formControlProps={{
                        fullWidth: true,
                      }}
                      className={classes.control}
                      inputProps={{
                        name: "SecurityCode",
                        type: "text",
                        placeholder: "Security Token",
                        required: "required",
                        onChange: (e) => {
                          setSecurityCode(e.target.value);
                        },
                      }}
                    />
                    <div className={classes.Middle}>
                      <div>
                        <Button
                          disabled={step === 0}
                          onClick={handleBack}
                          color="primary"
                          className={classes.button}
                        >
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          className={classes.button}
                        >
                          {step === steps.length - 1 ? "Finish" : "Next"}
                        </Button>
                      </div>
                    </div>
                  </form>
                </GridItem>
              </GridContainer>
            </div>
          </div>
          <div
            style={{ display: step == 2 ? "block" : "none" }}
            className="budget-cell d-flex flex-column"
          >
            <div className="center-container-940">
              <GridContainer alignItems="center" justify="center">
                <GridItem xs={12} sm={12} md={12}>
                  <h4>
                    You have successfully changed your{" "}
                    {CredentialToReset == "password" ? "password" : "pin"}
                  </h4>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
