import * as ACTION_TYPES from "../actions/action_types";

export const initialState = {
  sessionTimeout: 150000,
  overallTimeout: 0,
  startOverallTimeout: false,
};

export const TimeOutReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_SESSIONTIMEOUT:
      return {
        ...state,
        sessionTimeout: action.payload,
      };
    case ACTION_TYPES.SET_OVERALLTIMEOUT:
      return {
        ...state,
        overallTimeout: action.payload,
      };
    case ACTION_TYPES.SET_STARTOVERALLTIMEOUT:
      return {
        ...state,
        startOverallTimeout: action.payload,
      };
    default:
      return state;
  }
};
