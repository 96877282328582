import React, { useContext, useMemo } from "react";
import {
  primaryColor,
  primaryDarkColor,
  successColor,
  dangerColor,
  primaryBoxShadow,
} from "assets/jss/xrs.js";
import { cameraActions } from "../../../store/redux/redux-actions/redux_actions";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
let drawerWidth = 150;
if (isMobile) {
  drawerWidth = 0;
}

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "10px",
  marginBottom: "17px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: primaryDarkColor[3],
  borderStyle: "dashed",
  backgroundColor: primaryDarkColor[3],
  color: "white",
  outline: "none",
  cursor: "pointer",
  transition: "color .24s ease-in-out",
};

const activeStyle = {
  borderColor: primaryColor[0],
  color: primaryColor[0],
};

const acceptStyle = {
  borderColor: successColor[0],
  color: successColor[0],
};

const rejectStyle = {
  color: dangerColor[0],
  borderColor: dangerColor[0],
};

export default function DocumentSection(props) {
  const [text, setText] = React.useState(
    isMobile
      ? "Tap to upload"
      : "Drag 'n' drop some files here, or click to select files"
  );

  React.useEffect(() => {
    if (props.uploadedDocument != "") {
      let txt = "Uploaded documents: ";

      if (props.uploadedDocument.name !== undefined) {
        txt = txt + props.uploadedDocument.name;
      }
      //from documents
      else {
        txt = txt + props.uploadedDocument.document.name;
      }

      setText(txt);
    } else {
      setText(
        isMobile
          ? "Tap to upload"
          : "Drag 'n' drop some files here, or click to select file"
      );
    }
  }, [props]);

  const dispatch = useDispatch();

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    maxFiles: 1,
    accept: ".pdf, image/jpeg, image/png",
    onDrop: (acceptedFiles) => {
      // window.open(URL.createObjectURL(acceptedFiles[0]));
      if (acceptedFiles[0]) {
        let docs = Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        });

        let document = {
          from: props.item.from,
          itemId: props.item.itemId,
          document: docs,
        };
        if (props.uploadedDocument != "") {
          dispatch(cameraActions.deleteDocument(props.uploadedDocument));
        }
        dispatch(cameraActions.addCapturedImage(document));
      }
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(props.uploadedDocument != "" ? acceptStyle : {}),
    }),
    [isDragActive, isDragReject, props.uploadedDocument]
  );

  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      <p style={{ margin: "auto" }}>{text}</p>
    </div>
  );
}
