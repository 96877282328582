// import { GpsFixed } from "@material-ui/icons";
import {
  primaryColor,
  primaryDarkColor,
  successColor,
  dangerColor,
  primaryBoxShadow,
} from "assets/jss/xrs.js";
const fabStyle = {
  fabContainer: {
    position: "fixed",
    bottom: "50px",
    right: "50px",
    zIndex: "999",
    cursor: "pointer",
    "&:hover,&:focus": {
      "& $fabOptions": {
        opacity: 1,
        transform: "scale(1)",
      },
    },
  },
  fabOptions: {
    listStyleType: "none",
    margin: "0",
    position: "absolute",
    bottom: "70px",
    right: "0",
    opacity: 0,
    transition: "all 0.3s ease",
    transform: "scale(0)",
    transformOrigin: "85% bottom",
    "&:hover,&:focus": {
      opacity: 1,
      transform: "scale(1)",
    },
    "& li": {
      display: "flex",

      justifyContent: "flex-end",
      padding: "5px",
    },
  },
  fabIconHolder: {
    width: "50px",
    height: "50px",
    borderRadius: "100%",
    background: primaryDarkColor[0],
    boxShadow: "0 6px 20px rgba(0,0,0,0.2)",
    "&:hover,&:focus": {
      background: primaryColor[0],
      ...primaryBoxShadow,
    },
    "& i": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      fontStyle: "25px",
      color: "#ffffff",
    },
  },
  fab: {
    "&:hover,&:focus": {
      background: primaryColor[0],
      ...primaryBoxShadow,
    },
    width: "60px",
    height: "60px",
    backgroundColor: primaryDarkColor[0],
  },

  fabLabel: {
    padding: "2px 5px",
    alignSelf: "center",
    userSelect: "none",
    whiteSpace: "nowrap",
    borderRadius: "3px",
    fontSize: "16px",
    background: "#666666",
    color: "#ffffff",
    boxShadow: "0 6px 20px rgba(0,0,0,0.2)",
    marginRight: "10px",
  },
};
export default fabStyle;
