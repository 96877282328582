import React, { useContext } from "react";
import history from "../../utils/history";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { primaryColor } from "assets/jss/xrs.js";

import { useFormik } from "formik";

import TextField from "components/CustomInput/CustomInput.js";
import Iframe from "react-iframe";
import { Collapse } from "@material-ui/core";
import clsx from "clsx";
import Link from "@material-ui/core/Link";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

import PayNow from "../SectionsPage/Sections/PayNowSection.js";

import html2canvas from "html2canvas";
import HeaderSectionLoggedID from "../SectionsPage/Sections/HeaderSectionLoggedID.js";
import BlueHeader from "../SectionsPage/Sections/BlueHeaderSection.js";
import Context from "../../utils/context";
import * as URLS from "../../utils/XRSAPIs";
import { isMobile } from "react-device-detect";
import { el } from "date-fns/locale";

let drawerWidth = 150;
if (isMobile) {
  drawerWidth = 0;
}
const useStyles2 = makeStyles((theme) => ({
  content: {
    paddingTop: 60,
    paddingBottom: 38,
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
}));

export default function PayNowPage(props) {
  const classes2 = useStyles2();
  const context = useContext(Context);
  const [page, setPage] = React.useState(1);

  return (
    <div style={{ backgroundColor: primaryColor[14] }}>
      <main
        className={clsx(classes2.content, {
          [classes2.contentShift]: context.stateDrawer,
        })}
      >
        <HeaderSectionLoggedID
          page="paynow"
          title="Pay Now"
        ></HeaderSectionLoggedID>

        <BlueHeader></BlueHeader>

        <PayNow showdate={false} enabled="true" source="PayNow"></PayNow>
      </main>
    </div>
  );
}
