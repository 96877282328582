/*eslint-disable*/ import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinksNoUser";
import landingPageStyle from "assets/jss/xrs/views/landingPageStyle.js";
import { primaryColor } from "assets/jss/xrs";
const useStyles = makeStyles(landingPageStyle);

export default function headerSection(props) {
  document.title = props.title;
  const classes = useStyles();
  return (
    <div>
      <Header
        color={primaryColor[6]}
        brand="XRS"
        links={<HeaderLinks dropdownHoverColor="primary" />}
        relative
        changeColorOnScroll={{
          height: 0,
          color: [primaryColor[6]],
        }}
      />
    </div>
  );
}
