import {
  container,
  title,
  main,
  whiteColor,
  mainRaised,
} from "assets/jss/xrs.js";
import { blackColor, primaryColor } from "assets/jss/xrs";
import { fontStyle } from "@material-ui/system";

const landingPageStyle = {
  container: {
    color: blackColor,
    ...container,
    zIndex: "2",
  },
  RightAlign: {
    textAlign: "right",
    position: "right",
  },
  CenterAlign: {
    textAlign: "center",
    position: "ricenterght",
  },
  LinkHome: {
    color: whiteColor,
    textDecoration: "underline",
  },
  middle: {
    "@media (min-width: 576px)": {
      paddingLeft: 10,
    },
    "@media (min-width: 768px)": {
      paddingLeft: 30,
    },
    "@media (min-width: 992px)": {
      paddingLeft: 50,
    },
    "@media (min-width: 1200px)": {
      paddingLeft: 120,
    },
  },
  middleInput: {
    "@media (min-width: 576px)": {
      paddingLeft: 10,
      paddingRight: 10,
    },
    "@media (min-width: 768px)": {
      paddingLeft: 30,
      paddingRight: 30,
    },
    "@media (min-width: 992px)": {
      paddingLeft: 50,
      paddingRight: 50,
    },
    "@media (min-width: 1200px)": {
      paddingLeft: 120,
      paddingRight: 120,
    },
  },
  middleright: {
    paddingLeft: 20,
  },
  centerText: {
    textAlign: "center",
    fontWeight: 400,
    fontSize: 14,
  },
  digitText: {
    "@media (min-width: 300px)": {
      textAlign: "center",
      wordBreak: "break-word",
      fontSize: 14,
    },
    "@media (min-width: 400px)": {
      textAlign: "center",
      wordBreak: "break-word",
      fontSize: 14,
    },
    "@media (min-width: 576px)": {
      textAlign: "center",
      wordBreak: "break-word",
      fontSize: 14,
    },
    "@media (min-width: 768px)": {
      textAlign: "center",
      wordBreak: "break-word",
      fontSize: 14,
    },
    "@media (min-width: 992px)": {
      textAlign: "center",
      textAlign: "-webkit-center",
      wordBreak: "break-word",
      fontSize: 14,
    },
    "@media (min-width: 1200px)": {
      textAlign: "center",
      textAlign: "-webkit-center",
      wordBreak: "break-word",
      fontSize: 14,
    },
  },
  BoldText: {
    fontWeight: 400,
  },
  iFrameTop: {
    marginTop: 30,
    borderRadius: 3,
    border: 0,
    backgroundColor: "white",
  },
  centerTextBlue: {
    textAlign: "center",
    fontWeight: 400,
    fontSize: 18,
    color: "rgba(0, 105, 162, 1)",
  },
  textLabel: {
    fontWeight: 600,
  },
  centerTextTitle: {
    textAlign: "center",
    fontWeight: 400,
    fontSize: 30,
  },
  centerTextSubTitle: {
    textAlign: "center",
    fontWeight: 400,
    fontSize: 16,
    opacity: 0.5,
  },
  whiteText: {
    fontWeight: 400,
    fontSize: 14,
    color: whiteColor,
  },
  centerOnlyText: {
    textAlign: "center",
    fontWeight: 500,
    fontSize: 32,
  },
  centerButton: {
    textAlign: "center",
    fontSize: 16,
    fontFamily: '"Rubik", "Helvetica", sans-serif',
    fontWeight: "400",
    lineHeight: "1.5em",
  },

  cardRadius: {
    borderRadius: 4,
    margin: 0,
    padding: "1px",
    paddingBottom: "1px !important",
  },
  control: {
    margin: 5,
  },
  controlWhite: {
    margin: 0,
    height: 40,
    borderRadius: 2,
    backgroundColor: whiteColor,
    color: "blackColor",
  },
  footerParagraph: {
    fontSize: 14,
    color: "#fff",
    fontWeight: 400,
  },
  footerTitle: {
    fontSize: 26,
    color: "#fff",
    fontWeight: 400,
  },
  whiteTextField: {
    backgroundColor: "white",
  },
  paper: {
    textAlign: "center",
    backgroundColor: "yellow",
  },
  paperLeft: {
    textAlign: "Left",
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "0px",
    minHeight: "32px",
    color: whiteColor,
    textDecoration: "none",
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0",
  },
  main: {
    ...main,
    backgroundColor: primaryColor[11],
  },
  linkButton: {
    padding: "30px 30px",
  },
  mainRaised: {
    ...mainRaised,
  },

  login: {
    backgroundColor: "transparent",
  },
  hide: {
    display: "none",
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto",
  },
  shieldText: {
    "@media (min-width: 300px)": {
      wordBreak: "break-word",
      fontSize: 16,
      fontWeight: 400,

      display: "inline-flex",
      alignItems: "center",
    },
    "@media (min-width: 400px)": {
      wordBreak: "break-word",
      fontSize: 18,
      fontWeight: 400,

      display: "inline-flex",
      alignItems: "center",
    },
    "@media (min-width: 576px)": {
      wordBreak: "break-word",
      fontSize: 20,
      fontWeight: 400,

      display: "inline-flex",
      alignItems: "center",
    },
    "@media (min-width: 768px)": {
      wordBreak: "break-word",
      fontSize: 20,
      fontWeight: 400,
    },
    "@media (min-width: 992px)": {
      wordBreak: "break-word",
      fontSize: 20,
      fontWeight: 400,
      paddingTop: 7,
    },
    "@media (min-width: 1200px)": {
      wordBreak: "break-word",
      fontSize: 20,
      fontWeight: 400,
      paddingTop: 7,
    },
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
  },
  left: {
    float: "left!important",
    display: "block",
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right",
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative",
  },
  headerLoggedInColor: {
    backgroundColor: primaryColor[7],
  },
  bodyLoggedIn: {
    backgroundColor: primaryColor[14],
  },
};

export default landingPageStyle;
