import { isMobile } from "react-device-detect";
let drawerWidth = 150;
if (isMobile) {
  drawerWidth = 0;
}
const changePasswordOrPinPageStyle = (theme) => ({
  content: {
    paddingTop: 60,
    paddingBottom: 38,
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
});

export default changePasswordOrPinPageStyle;
