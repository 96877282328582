import * as ACTION_TYPES from "../actions/action_types";

export const initialState = {
  drawer: true,
  num: 1,
};

export const DrawerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_DRAWER:
      return {
        ...state,
        drawer: action.payload,
        num: action.num,
      };
    case ACTION_TYPES.FAILURE:
      return {
        ...state,
        drawer: "FAILURE",
        num: 0,
      };
    default:
      throw new Error();
  }
};
