/*eslint-disable*/ import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import HeaderLoggedIN from "components/Header/HeaderLoggedIN.js";
import HeaderLinks from "components/Header/HeaderLinksUser";
import landingPageStyle from "assets/jss/xrs/views/landingPageStyle.js";

const useStyles = makeStyles(landingPageStyle);

export default function HeaderSectionLoggedID(props) {
  const page = props.page;
  document.title = props.title;
  const classes = useStyles();
  return (
    <div>
      <HeaderLoggedIN
        color="white"
        brand="XRS"
        page={page}
        links={<HeaderLinks dropdownHoverColor="primary" />}
        relative
        changeColorOnScroll={{
          height: 0,
          color: "white",
        }}
      />
    </div>
  );
}
