import React from "react";
import Webcam from "react-webcam";
import FabOpenedCamera from "components/Fab/FabOpenedCamera.js";
import { cameraActions } from "../../store/redux/redux-actions/redux_actions";
import { useDispatch, useSelector } from "react-redux";
const videoConstraints = {
  facingMode: "environment",
};

const CustomCamera = () => {
  const webcamRef = React.useRef(null);
  const image = useSelector((state) => state.CameraReducer.capturedImage);
  const dispatch = useDispatch();
  const [changeCamera, setChangeCamera] = React.useState(false);
  const size = useWindowSize();

  const isLandscape = size.height <= size.width;
  const ratio = isLandscape
    ? size.width / size.height
    : size.width / size.height;

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    dispatch(cameraActions.setCapturedImage(imageSrc));
  }, [webcamRef]);
  const switchCamera = () => {
    setChangeCamera(!changeCamera);

    if (!changeCamera === true) {
      videoConstraints.facingMode = "user";
    } else {
      videoConstraints.facingMode = "environment";
    }
  };
  return (
    <>
      {image === "" ? (
        <Webcam
          height={size.height}
          width={size.width}
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={{ videoConstraints, aspectRatio: ratio }}
        />
      ) : (
        <img src={image} />
      )}

      <FabOpenedCamera
        changeCamera={switchCamera}
        capturePicture={capture}
      ></FabOpenedCamera>
    </>
  );
};

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  });
  React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);
  return windowSize;
}

export default CustomCamera;
