import React, { useContext } from "react";
import history from "../../../utils/history";
import {
  apiActions,
  getSiteDetailsActions,
  htmlActions,
} from "store/redux/redux-actions/redux_actions";
import * as ACTIONS from "store/actions/actions";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { useDispatch, useSelector } from "react-redux";
import { ApiErrorHandler } from "utils/apiErrorHandler";
import InputAdornment from "@material-ui/core/InputAdornment";

import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { useFormik } from "formik";
import landingPageStyle from "assets/jss/xrs/views/landingPageStyle.js";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

import fnmoment from "moment/min/moment-with-locales";

import TextField from "components/CustomInput/CustomInput.js";
import Iframe from "react-iframe";
import { Collapse } from "@material-ui/core";
import clsx from "clsx";
import Link from "@material-ui/core/Link";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

import html2canvas from "html2canvas";
import Context from "../../..//utils/context";
import * as URLS from "../../../utils/XRSAPIs";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { el } from "date-fns/locale";

let drawerWidth = 150;
if (isMobile) {
  drawerWidth = 0;
}
// const useStyles2 = makeStyles((theme) => ({
//   content: {
//     paddingTop: 60,
//     paddingBottom: 38,
//     flexGrow: 1,
//     padding: theme.spacing(3),
//     transition: theme.transitions.create("margin", {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     marginLeft: 0,
//   },
//   contentShift: {
//     transition: theme.transitions.create("margin", {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//     marginLeft: drawerWidth,
//   },
// }));
let eventListenerCopy = null;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  Hide: {
    display: "none",
  },
  button: {
    marginRight: theme.spacing(1),
    padding: 13,
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  white: {
    color: "white",
    fontWeight: 500,
    paddingLeft: 60,
  },
  hide: {
    display: "none",
  },
  whiteRight: {
    color: "white",
    fontWeight: 500,
    textAlign: "right",
  },
  blueTitle: {
    color: "#0065a4",
    fontWeight: 400,
  },
  cardNOBORDER: {
    border: 0,
  },
  Middle: {
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    textAlign: "center",
    border: 0,
  },
  radioAlign: {
    marginLeft: "-10px",
  },
  blueText: {
    color: "#0065a4",
    fontWeight: 300,
  },
  Title: {
    fontWeight: 500,
    paddingTop: 30,
  },
  Wellcome: {
    fontWeight: 500,
  },
  BoxFull: {
    width: "100%",
  },
  rightValues: {
    textAlign: "right",
    fontWeight: 300,
  },
}));
const useStyles2 = makeStyles(landingPageStyle);

export default function PayNowSection(props) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.ApiCallsReducer.loading);
  const [loadingButon, setloadingButon] = React.useState(false);
  const currency = useSelector((state) => state.GetSiteDetailsReducer.currency);
  const convenienceFee = useSelector(
    (state) => state.GetSiteDetailsReducer.convenienceFee
  );

  const termsQuickPay = useSelector((state) => state.HtmlReducer.termsQuickPay);
  const termsPlan = useSelector((state) => state.HtmlReducer.termsPlan);
  const termsDiscount = useSelector((state) => state.HtmlReducer.termsDiscount);
  const [usesconveniencefee, setUsesconveniencefee] = React.useState(false);

  const [paymentgatewayurl, setPaymentgatewayurl] = React.useState("");

  const [note, setNote] = React.useState("");

  const classes2 = useStyles2();
  const context = useContext(Context);
  const [page, setPage] = React.useState(1);
  const [ifamevisible, setIfamevisible] = React.useState(false);
  const [sitename, setSitename] = React.useState("");
  const [companyname, setCompanyname] = React.useState("");
  const [aboutus, setAboutus] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [officehours, setOfficehours] = React.useState("");
  const [phonenumber, setPhonenumber] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [selectedDate, handleDateChange] = React.useState(new Date());
  const [screenstate, setScreenstate] = React.useState({ screenshot: "" });
  const [showQuickPay, setShowQuickPay] = React.useState(true);
  const [termsClicked, setTermsCliked] = React.useState(false);
  const [termsApiText, setTermsApiText] = React.useState("");
  const [termsText, setTermsText] = React.useState("");
  const [cardref, setCardref] = React.useState("");
  const [cufphone, setCufphone] = React.useState("");
  const [paymentDate, setPaymentDate] = React.useState("");
  const [minPayment, setMinPayment] = React.useState(1);
  const stateCustomer = useSelector((state) => state.CustomerReducer.customer);
  const stateOffer = useSelector((state) => state.OfferReducer.offer);

  const [value, setValue] = React.useState("new");

  const [amount, setAmmount] = React.useState("");

  const stateSelectedAccount = useSelector(
    (state) => state.SelectedAccountsReducer.account
  );

  const paymentProvider = useSelector(
    (state) => state.GetSiteDetailsReducer.paymentProvider
  );

  const ChangeNote = (event) => {
    setNote(event.target.value);
  };
  const HideIframe = () => {
    setIfamevisible(false);
  };
  const ShowIframe = () => {
    setIfamevisible(true);
  };
  const handleTermsMobile = () => {
    setShowQuickPay(false);
  };
  const [quickPayAPIData, setquickPayAPIData] = React.useState(undefined);

  React.useEffect(() => {
    if (quickPayAPIData) {
      window.addEventListener("message", tabaPayMessage, false);
    }
    return () => {
      window.removeEventListener("message", tabaPayMessage);
    };
  }, [quickPayAPIData]);
  React.useEffect(() => {
    fnmoment.locale(window.navigator.language.toLowerCase());
    let today = fnmoment().format("YYYY-MM-DD");
    setPaymentDate(today);
    dispatch(ACTIONS.set_PaymentStatusFrom(props.source === "PayNow"));
  }, []);
  React.useEffect(() => {
    window.RealexHpp = null;

    const script = document.createElement("script");
    script.src = "rxp-js.js";
    script.async = true;
    document.body.appendChild(script);

    fetch(URLS.GetSiteDetatilsAPIUrl, {
      method: "GET",
    })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          return res.json();
        } else {
          res.json().then((data) => {
            ApiErrorHandler("Get Site Detatils", data, context);
          });
          throw Error("");
        }
      })
      .then((response) => {
        dispatch(getSiteDetailsActions.logo(response.logoimageurl));
        dispatch(getSiteDetailsActions.footerImage(response.footerimageurl));
        dispatch(getSiteDetailsActions.currency(response.sitecurrency));
        dispatch(getSiteDetailsActions.set4week(response.usE4WEEKLYFREQUENCY));

        dispatch(
          getSiteDetailsActions.uploadImagesToBudget(
            response.uploadimagestobudget
          )
        );
        if (response.usesconveniencefee) {
          dispatch(
            getSiteDetailsActions.convenienceFee(response.conveniencefee)
          );
        }
        setSitename(response.sitename);
        setCompanyname(response.companyname);
        setAboutus(response.aboutus);
        setOfficehours(response.officehours);
        setPhonenumber(response.phonenumber);
        setAddress(response.address);
        setEmail(response.email);
        getMenu();
        setTermsApiText(response.termsquickpay);
        setCardref(stateCustomer.cardref);
        stateCustomer.cardref ? setValue("card") : setValue("new");
        setMinPayment(stateCustomer.paynowmin);
        setUsesconveniencefee(response.usesconveniencefee);
        setPaymentgatewayurl(response.paymentgatewayurl);

        setCufphone(stateCustomer.cuphone);
      })
      .catch((error) => {
        if (error.message == "") {
        } else {
          ApiErrorHandler("Get Site Detatils", error, context);
        }
      });
    return () => {
      dispatch(apiActions.clear());

      document.body.removeChild(script);
      window.RealexHpp = null;
      if (eventListenerCopy != null) {
        window.removeEventListener("message", eventListenerCopy, false);
      }
    };
  }, []);
  const formik = useFormik({
    initialValues: {
      amount: value,
    },
    onSubmit: (values) => {
      if (!isMobile) {
        dispatch(apiActions.loading(true));
        setImageBase64();
      }

      if (isMobile) {
        if (termsClicked) {
          dispatch(apiActions.loading(true));
          setImageBase64();
        } else {
          setShowQuickPay(false);
        }
      }
    },
  });

  const RedirectResponse = (RedirecURL) => {
    getMenu();

    history.push(RedirecURL);
  };
  const RelalexInit = (values) => {
    var url = URLS.PayNowResponseNoCard;
    // ;
    if (props.source == "CreatePaymentPlan" || props.source == "ViewOffer") {
      url = URLS.WebOfferResponseNoCard;
    }
    if (props.oneMonth) {
      url = URLS.OnePaymentWebOfferResponseNoCard;
    }

    window.RealexHpp.setHppUrl(paymentgatewayurl);
    eventListenerCopy = window.RealexHpp.embedded.init(
      "payButtonId",
      "paymentIframeID",
      url,
      values,
      RedirectResponse
    );
  };
  const HandleTermsClicked = (e) => {
    if (e.target.checked) {
      setTermsText("I agree ");
    } else {
      setTermsText("I don't agree ");
    }
    setTermsCliked(e.target.checked);
    setShowQuickPay(true);
  };
  const submitFormPayNow = (values, screen) => {
    const token = sessionStorage.getItem("access_token");
    setloadingButon(true);
    var url;
    if (paymentProvider.toUpperCase() == "TABAPAY") {
      url = URLS.CreateCardRequestTabaPay;
    } else {
      url = URLS.PayNowURLNoCard;
    }
    var Amount = values;

    var data;
    var iremcode = stateSelectedAccount.itemcode;
    var recno = stateSelectedAccount.recno;
    var cardref = stateCustomer.cardref;
    if (value == "card") {
      data = {
        ItemRecno: recno,
        ItemCode: iremcode,
        PAYMENTAMOUNT: Amount,
        ScreenShot: screen,
        CURRENCYSYMBOL: "EUR",
        PAYERREF: stateCustomer.cardpayerref,
        CARDREF: cardref,
      };
      if (paymentProvider.toUpperCase() == "TABAPAY") {
        url = URLS.UseExistingCardTabaPay;
        if (usesconveniencefee) {
          data.NOTE = note;
        }
      } else {
        url = URLS.PayNowURLCard;
      }
      data = JSON.stringify(data);
    } else {
      data = JSON.stringify({
        ItemRecno: recno,
        ItemCode: iremcode,
        Amount: Amount,
        ScreenShot: screen,
      });
    }

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: data,
    })
      .then((res) => {
        return res.json();
      })
      .then(
        (data) => {
          dispatch(apiActions.loading(false));
          if (
            (data.MESSAGE != undefined || data.message != undefined) &&
            data.AUTHCODE == null
          ) {
            setloadingButon(false);
            ApiErrorHandler("Pay Now", data, context);
          } else {
            if (value == "card") {
              history.push(
                "/paynow-paymentstatus?AUTHCODE=" +
                  data.AUTHCODE +
                  "&MESSAGE=" +
                  data.MESSAGE +
                  "&RESULTCODE=" +
                  data.RESULTCODE +
                  "&AMOUNT=" +
                  data.AMOUNT +
                  "&PASSREF=" +
                  data.PASSREF
              );
            } else {
              if (paymentProvider.toUpperCase() == "TABAPAY") {
                setquickPayAPIData(data);
              } else {
                RelalexInit(data);
              }
              if (isMobile) {
                setShowQuickPay(false);
              }
              setIfamevisible(true);
            }
          }
        },
        [page]
      )
      .catch((error) => {
        dispatch(apiActions.loading(false));
        setloadingButon(false);
        if (error.AUTHCODE) {
          if (error.message == "") {
          } else {
            if (stateCustomer.cardref) {
              history.push(
                "/paynow-paymentstatus?AUTHCODE=" +
                  data.AUTHCODE +
                  "&MESSAGE=" +
                  data.MESSAGE +
                  "&RESULTCODE=" +
                  data.RESULTCODE +
                  "&AMOUNT=" +
                  data.AMOUNT +
                  "&PASSREF=" +
                  data.PASSREF
              );
            }
          }

          ApiErrorHandler("Pay Now ", error, context);
        }
      });
  };
  var tabaPayMessage = function(event) {
    //Check if it is from TabaPay
    let iframe = document.getElementById("SSO");

    if (typeof event.data === "string") {
      if (event.data != "Close") {
        if (event.data.slice(0, 7) == "Error: ") {
          // Error
        } else {
          var asData = event.data.split("|");
          if (asData.length == 3) {
            let quickPayData = quickPayAPIData;
            let url;

            if (
              props.source == "CreatePaymentPlan" ||
              props.source == "ViewOffer"
            ) {
              url = URLS.SaveOfferResultTabaPay;

              if (props.oneMonth || props.source == "ViewOffer") {
                url = URLS.SaveSettlementOfferResultabaPay;
              }
            }
            if (props.source == "PayNow") {
              url = URLS.PayNowAndSaveCardResultTabaPay;
              if (usesconveniencefee) {
                quickPayData.NOTE = note;
              }
            }

            quickPayData.LASTDIGITS = asData[0];
            quickPayData.EXPIRATIONDATE = asData[1];
            quickPayData.CARDTOKEN = asData[2];
            var token = sessionStorage.getItem("access_token");

            fetch(url, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
              },
              body: JSON.stringify(quickPayData),
            })
              .then((res) => {
                if (res.ok) {
                  return res.json();
                } else {
                  res.json().then((data) => {
                    ApiErrorHandler("Pay now", data, context);
                  });
                  setIfamevisible(false);
                  setloadingButon(false);
                  window.removeEventListener("message", tabaPayMessage);
                  iframe.src = iframe.src;
                  throw Error("");
                }
              })
              .then((response) => {
                history.push(response.REDIRECTURL);
              })
              .catch((error) => {
                // if (error.message == "") {
                //   ;
                // } else {
                //   ApiErrorHandler("Get Site Detatils", error, context);
                // }
              });
          } else {
            // Data Error
          }
        }
      } else {
        setIfamevisible(false);
        setloadingButon(false);
        window.removeEventListener("message", tabaPayMessage);
        iframe.src = iframe.src;
      }
    }
  };
  const getMenu = () => {
    var url = URLS.GetWelcomeNavigation;
    var token = sessionStorage.getItem("access_token");

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          return res.json();
        } else {
          res.json().then((data) => {
            ApiErrorHandler("Welcome Navigation", data, context);
          });
          throw Error("");
        }
      })
      .then((data1) => {
        dispatch(ACTIONS.set_menu(data1));

        fetch(URLS.GetWelcomeDetails, {
          method: "GET",
          headers: { Authorization: "Bearer " + token },
        })
          .then((res) => {
            if (res.status == 200 || res.status == 201) {
              return res.json();
            } else {
              ApiErrorHandler("Welcome Details", res, context);
              throw Error("");
            }
          })
          .then((data) => {
            dispatch(htmlActions.termsQuickPay(data.customer.termsquickpay));
            dispatch(htmlActions.termsPlan(data.customer.termsplan));
            dispatch(htmlActions.termsDiscount(data.customer.termsdiscount));

            dispatch(
              htmlActions.viewOfferText(data.activeAccounts[0].viewoffertext)
            );
            dispatch(
              htmlActions.createPlanText(data.activeAccounts[0].createplantext)
            );
            dispatch(
              htmlActions.createPlantoc(data.activeAccounts[0].createplantoc)
            );
            dispatch(
              htmlActions.createBudgetText(
                data.activeAccounts[0].createbudgettext
              )
            );
            dispatch(
              htmlActions.viewAccountText(
                data.activeAccounts[0].viewacccounttext
              )
            );
            dispatch(ACTIONS.set_Customer(data.customer));

            sessionStorage.setItem("user", data.customer.cufname);

            dispatch(ACTIONS.set_Accounts(data.activeAccounts));

            dispatch(ACTIONS.set_Callbacks(data.callbackReasons));
            dispatch(ACTIONS.set_num_accounts(data.activeAccounts.length));
            if (data.activeAccounts.length == 1) {
              dispatch(ACTIONS.set_selectedAccount(data.activeAccounts[0]));
            }
            dispatch(apiActions.clear());
          })
          .catch((error) => {
            if (error.message == "") {
            } else {
              ApiErrorHandler("Welcome Details", error, context);
            }
          });
      })
      .catch((error) => {
        if (error.message == "") {
        } else {
          ApiErrorHandler("Welcome Navigation", error, context);
        }
      });
  };
  const submitFormPaymentPlan = (values, screen) => {
    dispatch(apiActions.loading(true));
    setloadingButon(true);
    debugger;
    var url;
    const token = sessionStorage.getItem("access_token");
    if (paymentProvider.toUpperCase() == "TABAPAY") {
      url = URLS.CreateOfferRequestTabaPay;
    } else {
      url = URLS.GetWebOffersAPIUrl;
    }

    var cardref = stateCustomer.cardref;
    var postfreq =
      values.frequency == "Week"
        ? "Weekly"
        : values.frequency == "FORT"
        ? "FORT"
        : values.frequency == "4WEEKLY"
        ? "4WEEKLY"
        : "Monthly";

    var data;
    if (value == "card") {
      if (paymentProvider.toUpperCase() == "TABAPAY") {
        url = URLS.SetWebOfferTabaPay;
      } else {
        url = URLS.GetWebOffersCardAPIUrl;
      }
      data = JSON.stringify({
        OFFERID: stateOffer.WOOFFERID,
        DOWNPAYMENT: values.downpayment,
        REMAINGBAL: values.balanceToPay,
        STARTDATE: values.startdate,
        FREQUENCY: postfreq,
        AMOUNT: Number(values.amount).toFixed(2),
        FINALDATE: values.endDate,
        FINALAMOUNT: Number(values.amount).toFixed(2),
        APPLYDISCOUNT: "N",
        DISCOUNTOPTIONNUMBER: "0",
        SCREENSHOT: screen,
        PAYERREF: stateCustomer.cardpayerref,
        CARDREF: cardref,
      });
    } else {
      data = JSON.stringify({
        OFFERID: stateOffer.WOOFFERID,
        DOWNPAYMENT: values.downpayment,
        REMAINGBAL: values.balanceToPay,
        STARTDATE: values.startdate,
        FREQUENCY: postfreq,
        AMOUNT: Number(values.amount).toFixed(2),
        FINALDATE: values.endDate,
        FINALAMOUNT: Number(values.amount).toFixed(2),
        APPLYDISCOUNT: "N",
        DISCOUNTOPTIONNUMBER: "0",
        SCREENSHOT: screen,
      });
    }

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: data,
    })
      .then((res) => {
        dispatch(apiActions.loading(false));

        return res.json();
      })
      .then((data) => {
        if (
          (data.MESSAGE != undefined || data.message != undefined) &&
          data.AUTHCODE == null
        ) {
          ApiErrorHandler("Payment Plan", data, context);
        } else {
          getMenu();
          if (value == "card") {
            history.push(
              "/paynow-paymentstatus?AUTHCODE=" +
                data.AUTHCODE +
                "&MESSAGE=" +
                data.MESSAGE +
                "&RESULTCODE=" +
                data.RESULTCODE +
                "&AMOUNT=" +
                data.AMOUNT +
                "&PASSREF=" +
                data.PASSREF
            );
          } else {
            if (paymentProvider.toUpperCase() == "TABAPAY") {
              setquickPayAPIData(data);
            } else {
              RelalexInit(data);
            }
            if (isMobile) {
              setShowQuickPay(false);
            }
            setIfamevisible(true);
          }
        }
      })
      .catch((error) => {
        if (error.message == "") {
        } else {
          dispatch(apiActions.loading(false));

          ApiErrorHandler("Payment Plan", error, context);
        }
      });
  };
  const submitFormViewOffer = (values, screen) => {
    const token = sessionStorage.getItem("access_token");

    var url;
    if (paymentProvider.toUpperCase() == "TABAPAY") {
      url = URLS.CreateOfferRequestTabaPay;
    } else {
      url = URLS.GetWebOffersAPIUrl;
    }
    dispatch(apiActions.loading(true));
    setloadingButon(true);
    var data;
    var end = paymentDate;
    var dateSetlementOffer = fnmoment
      .utc(fnmoment.utc().format())
      .local()
      .format();
    end = fnmoment(end)
      .add(values.TERM * 1, "M")
      .format("YYYY-MM-DD");
    if (value == "card") {
      if (props.oneMonth) {
        if (paymentProvider.toUpperCase() == "TABAPAY") {
          url = URLS.SetWebSettlementOfferTabaPay;
        } else {
          url = URLS.OnePaymentPayNowURLWebOfferUseExistingCard;
        }
        data = JSON.stringify({
          OFFERID: values.OFFERID,
          DOWNPAYMENT: values.DOWNPAYMENT,
          REMAINGBAL: values.REMAINGBAL,
          OFFERACCEPTEDDECLINED: "A",
          OFFERDECLINEDREASON: "OK",
          STARTDATE: dateSetlementOffer,
          FREQUENCY: "ONCEOFF",
          AMOUNT: Number(values.AMOUNT).toFixed(2),
          FINALDATE: dateSetlementOffer,
          FINALAMOUNT: Number(values.FINALAMOUNT).toFixed(2),
          APPLYDISCOUNT: "Y",
          DISCOUNTOPTIONNUMBER: values.DISCOUNTOPTIONNUMBER,
          SCREENSHOT: screen,
          PAYERREF: stateCustomer.cardpayerref,
          CARDREF: stateCustomer.cardref,
        });
      } else {
        if (paymentProvider.toUpperCase() == "TABAPAY") {
          url = URLS.SetWebOfferTabaPay;
        } else {
          url = URLS.GetWebOffersCardAPIUrl;
        }
        data = JSON.stringify({
          OFFERID: values.OFFERID,
          DOWNPAYMENT: values.DOWNPAYMENT,
          REMAINGBAL: values.REMAINGBAL,
          OFFERACCEPTEDDECLINED: "A",
          OFFERDECLINEDREASON: "OK",
          STARTDATE: paymentDate,
          FREQUENCY: values.FREQUENCY,
          AMOUNT: Number(values.AMOUNT).toFixed(2),
          FINALDATE: end,
          FINALAMOUNT: Number(values.FINALAMOUNT).toFixed(2),
          APPLYDISCOUNT: "Y",
          DISCOUNTOPTIONNUMBER: values.DISCOUNTOPTIONNUMBER,
          SCREENSHOT: screen,
          PAYERREF: stateCustomer.cardpayerref,
          CARDREF: stateCustomer.cardref,
        });
      }
    } else {
      if (props.oneMonth) {
        if (paymentProvider.toUpperCase() == "TABAPAY") {
          url = URLS.CreateSettlementOfferRequestTabaPay;
        } else {
          url = URLS.OnePaymentURLSettlementOfferNoCard;
        }
        data = JSON.stringify({
          OFFERID: values.OFFERID,
          DOWNPAYMENT: values.DOWNPAYMENT,
          REMAINGBAL: values.REMAINGBAL,
          OFFERACCEPTEDDECLINED: "A",
          OFFERDECLINEDREASON: "OK",
          STARTDATE: dateSetlementOffer,
          FREQUENCY: "ONCEOFF",
          AMOUNT: Number(values.AMOUNT).toFixed(2),
          FINALDATE: dateSetlementOffer,
          FINALAMOUNT: Number(values.FINALAMOUNT).toFixed(2),
          APPLYDISCOUNT: "Y",
          DISCOUNTOPTIONNUMBER: values.DISCOUNTOPTIONNUMBER,
          SCREENSHOT: screen,
        });
      } else {
        if (paymentProvider.toUpperCase() == "TABAPAY") {
          url = URLS.CreateOfferRequestTabaPay;
        } else {
          url = URLS.GetWebOffersCardAPIUrl;
        }
        data = JSON.stringify({
          OFFERID: values.OFFERID,
          DOWNPAYMENT: values.DOWNPAYMENT,
          REMAINGBAL: values.REMAINGBAL,
          OFFERACCEPTEDDECLINED: "A",
          OFFERDECLINEDREASON: "OK",
          STARTDATE: paymentDate,
          FREQUENCY: values.FREQUENCY,
          AMOUNT: Number(values.AMOUNT).toFixed(2),
          FINALDATE: end,
          FINALAMOUNT: Number(values.FINALAMOUNT).toFixed(2),
          APPLYDISCOUNT: "Y",
          DISCOUNTOPTIONNUMBER: values.DISCOUNTOPTIONNUMBER,
          SCREENSHOT: screen,
        });
      }
    }

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: data,
    })
      .then((res) => {
        dispatch(apiActions.loading(false));

        return res.json();
      })
      .then((data) => {
        if (
          (data.MESSAGE != undefined || data.message != undefined) &&
          data.AUTHCODE == null
        ) {
          ApiErrorHandler("Offer Plan", data, context);
        } else {
          if (value == "card") {
            getMenu();
            history.push(
              "/paynow-paymentstatus?AUTHCODE=" +
                data.AUTHCODE +
                "&MESSAGE=" +
                data.MESSAGE +
                "&RESULTCODE=" +
                data.RESULTCODE +
                "&AMOUNT=" +
                data.AMOUNT +
                "&PASSREF=" +
                data.PASSREF
            );
          } else {
            if (paymentProvider.toUpperCase() == "TABAPAY") {
              setquickPayAPIData(data);
            } else {
              RelalexInit(data);
            }
            if (isMobile) {
              setShowQuickPay(false);
            }
            setIfamevisible(true);
          }
        }
      })
      .catch((error) => {
        if (error.message == "") {
        } else {
          ApiErrorHandler("Offer Plan", error, context);
        }
      });
  };

  const handlePayDateChange = (e) => {
    if (e.target.value == "") {
      setPaymentDate("0.00");
    } else {
      setPaymentDate(e.target.value);
    }
  };

  const setImageBase64 = () => {
    html2canvas(document.getElementById("screen"), {
      width: 800,
      height: 600,
    }).then(function(canvas) {
      const newScreenshot = canvas.toDataURL("image/png");
      setScreenstate({ newScreenshot });
      if (props.source == "PayNow") {
        submitFormPayNow(
          convenienceFee == 0
            ? amount
            : (Number(amount) + convenienceFee).toFixed(2),
          newScreenshot
        );
      } else if (props.source == "CreatePaymentPlan") {
        submitFormPaymentPlan(props.value, newScreenshot);
      } else if (props.source == "ViewOffer") {
        submitFormViewOffer(props.value, newScreenshot);
      }
    });
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleChangeAmmount = (event) => {
    setAmmount(event.target.value);
  };

  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card
            style={{
              paddingLeft: "10px",
              paddingBottom: "10px",
              paddingRight: "10px",
              margin: 0,
            }}
          >
            <CardBody style={{ padding: "3px" }}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <Collapse in={showQuickPay}>
                        <div id="screen">
                          {props.source != "PayNow" ? (
                            <h2 className={classes.centerOnlyText}>Payment</h2>
                          ) : (
                            <h2 className={classes.centerOnlyText}>Pay Now</h2>
                          )}

                          {props.message ? (
                            <div
                              style={{
                                display: "inline-block",
                                marginLeft: 5,
                                marginRight: 5,
                              }}
                            >
                              {props.message}
                              {props.showdate
                                ? paymentDate
                                  ? " " + fnmoment(paymentDate).format("L")
                                  : ""
                                : null}
                            </div>
                          ) : (
                            ReactHtmlParser(stateSelectedAccount.paynowtext)
                          )}

                          <form
                            className={classes.form}
                            onSubmit={formik.handleSubmit}
                          >
                            {props.enabled == "true" ? (
                              <TextField
                                label="Amount"
                                required
                                showCurrency
                                className={classes.control}
                                inputProps={{
                                  required: "required",
                                  type: "number",
                                  step: 0.01,
                                  name: "amount",
                                  value: amount,
                                  min: minPayment,

                                  onChange: handleChangeAmmount,
                                }}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            ) : null}
                            {convenienceFee == 0 ||
                            props.enabled != "true" ? null : (
                              <div
                                style={{ width: "100%", marginBottom: "1rem" }}
                              >
                                <span
                                  style={{
                                    width: "50%",
                                    textAlign: "left",
                                    fontWeight: "600",
                                    display: "inline-block",
                                  }}
                                >
                                  Debit amount is{" "}
                                </span>
                                <span
                                  style={{
                                    width: "50%",
                                    textAlign: "right",
                                    fontWeight: "600",
                                    display: "inline-block",
                                  }}
                                >
                                  {currency + " "}{" "}
                                  {amount != ""
                                    ? (Number(amount) + convenienceFee).toFixed(
                                        2
                                      )
                                    : 0}
                                </span>
                              </div>
                            )}
                            {props.showdate ? (
                              <TextField
                                views={["year", "month", "date"]}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                variant="outlined"
                                inputProps={
                                  props.value.MAXPAYDATE
                                    ? {
                                        type: "date",
                                        required: "required",
                                        max: fnmoment(
                                          props.value.MAXPAYDATE
                                        ).format("YYYY-MM-DD"),
                                        min: fnmoment().format("YYYY-MM-DD"),
                                        onChange: (e) => {
                                          handlePayDateChange(e);
                                        },
                                        pattern: "d{4}-d{2}-d{2}",
                                        name: "paydate",
                                        value: paymentDate,
                                      }
                                    : {
                                        type: "date",
                                        required: "required",
                                        min: fnmoment().format("YYYY-MM-DD"),
                                        onChange: (e) => {
                                          handlePayDateChange(e);
                                        },
                                        pattern: "d{4}-d{2}-d{2}",
                                        name: "paydate",
                                        value: paymentDate,
                                      }
                                }
                                className={classes.control}
                              ></TextField>
                            ) : null}
                            {usesconveniencefee ? (
                              <TextField
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                label="Note"
                                multiline
                                variant="outlined"
                                inputProps={{
                                  type: "text",
                                  maxLength: 256,
                                  name: "Note",
                                  value: note,
                                  onChange: (e) => {
                                    ChangeNote(e);
                                  },
                                }}
                                className={classes.control}
                              ></TextField>
                            ) : null}
                            <BrowserView>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    inputProps={{
                                      required: true,
                                      name: "terms",
                                    }}
                                    color="primary"
                                  />
                                }
                                label="I agree with the Terms and Conditions"
                              />
                            </BrowserView>
                            <MobileView>
                              <Link onClick={handleTermsMobile}>
                                {" "}
                                <b>{termsText}</b>
                                {termsText == "" ? "" : "with the"} Terms and
                                Conditions {termsText == "" ? "Apply" : ""}
                              </Link>
                            </MobileView>

                            {cardref ? (
                              <div>
                                <BrowserView>
                                  <Radio
                                    className={classes.radioAlign}
                                    checked={value === "card"}
                                    onChange={handleChange}
                                    value="card"
                                    name="radio-button-demo"
                                  />
                                  Use existing {stateCustomer.cardastdigits}{" "}
                                  <Radio
                                    checked={value === "new"}
                                    onChange={handleChange}
                                    value="new"
                                    name="radio-button-demo"
                                  />
                                  Use New Card{" "}
                                </BrowserView>
                                <MobileView>
                                  {/* <Radio
                                    className={classes.radioAlign}
                                    checked={value === "card"}
                                    onChange={handleChange}
                                    value="card"
                                    name="radio-button-demo"
                                  />
                                  Use existing {stateCustomer.cardastdigits}
                                  <br />
                                  <Radio
                                    className={classes.radioAlign}
                                    checked={value === "new"}
                                    onChange={handleChange}
                                    value="new"
                                    name="radio-button-demo"
                                  />
                                  Use New Card{" "} */}
                                  <RadioGroup
                                    style={{ paddingLeft: "10px" }}
                                    aria-label="Payment"
                                    name="Payment"
                                  >
                                    <FormControlLabel
                                      value="card"
                                      control={
                                        <Radio
                                          className={classes.radioAlign}
                                          checked={value === "card"}
                                          onChange={handleChange}
                                          value="card"
                                          name="radio-button-demo"
                                        />
                                      }
                                      label={
                                        "Use existing " +
                                        stateCustomer.cardastdigits
                                      }
                                    />
                                    <FormControlLabel
                                      value="new"
                                      control={
                                        <Radio
                                          className={classes.radioAlign}
                                          checked={value === "new"}
                                          onChange={handleChange}
                                          value="new"
                                          name="radio-button-demo"
                                        />
                                      }
                                      label="Use New Card"
                                    />
                                  </RadioGroup>
                                </MobileView>
                              </div>
                            ) : (
                              ""
                            )}

                            <div className={classes.textCenter}>
                              <Button
                                disabled={loading || loadingButon}
                                type="submit"
                                color="primary"
                                fullWidth
                              >
                                Proceed to pay
                              </Button>
                            </div>
                          </form>
                        </div>
                      </Collapse>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <BrowserView>
                        <Collapse in={!ifamevisible}>
                          <br></br>

                          {props.source === "PayNow"
                            ? termsQuickPay
                            : props.source === "CreatePaymentPlan"
                            ? termsPlan
                            : props.source === "ViewOffer"
                            ? termsDiscount
                            : termsQuickPay}
                        </Collapse>
                      </BrowserView>
                      <MobileView>
                        <Collapse in={!ifamevisible && !showQuickPay}>
                          <br></br>

                          {props.source === "PayNow"
                            ? termsQuickPay
                            : props.source === "CreatePaymentPlan"
                            ? termsPlan
                            : props.source === "ViewOffer"
                            ? termsDiscount
                            : null}

                          <Card>
                            <CardBody>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={HandleTermsClicked}
                                    inputProps={{
                                      required: true,
                                      name: "terms",
                                    }}
                                    color="primary"
                                  />
                                }
                                label="I agree with the Terms and Conditions"
                              />
                            </CardBody>
                          </Card>
                        </Collapse>
                      </MobileView>
                      <Collapse in={ifamevisible}>
                        {paymentProvider.toUpperCase() == "TABAPAY" ? (
                          <Iframe
                            url={paymentgatewayurl}
                            id="SSO"
                            width="100%"
                            height="350px"
                            position="relative"
                            className={classes2.iFrameTop}
                            styles={{ margin: "25px" }}
                          />
                        ) : (
                          <Iframe
                            id="paymentIframeID"
                            width="100%"
                            height="578.8px"
                            position="relative"
                            className={classes2.iFrameTop}
                            styles={{ margin: "25px" }}
                          />
                        )}
                      </Collapse>
                      <Button
                        id="payButtonId"
                        className={classes.hide}
                        color="primary"
                        fullWidth
                      >
                        REALEX NOW
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
