import imagesStyles from "../../imagesStyles";
import { primaryColor } from "assets/jss/xrs.js";

const budgetSectionStyle = {
  ...imagesStyles,
  showSummary: {
    display: "visible",
  },
  hideSummary: {
    display: "none",
  },
  white: {
    color: "white",
    fontWeight: 500,
    paddingLeft: 20,
  },
  container: {
    padding: 40,
  },
  button: {
    textAlign: "center",
  },
  linkButton: {
    paddingLeft: "5px",
    paddingRight: "5px",

    display: "inline",
    width: 15,
    textAlign: "center",
  },
  whiteRight: {
    color: "white",
    fontWeight: 500,
    textAlign: "right",
  },
  blueTitle: {
    color: "#0065a4",
    fontWeight: 400,
  },
  Summary: {
    color: primaryColor[20],
    margin: 0,
  },
  Title: {
    fontWeight: 500,
    paddingTop: 30,
    margin: 0,
  },
  TitleTop: {
    fontWeight: 500,
    margin: 0,
    textAlign: "right",
  },
  SubTitle: {
    fontWeight: 400,
    paddingTop: 0,
    margin: 0,
  },
  Wellcome: {
    fontWeight: 500,
  },
  Money: {
    textAlign: "right",
  },
  MoneyGreen: {
    textAlign: "right",
    color: "#4BB543",
  },
  Submited: {
    textAlign: "left",
    color: "#4BB543",
    fontSize: "20px",
  },
  MoneyRed: {
    textAlign: "right",
    color: "#ff0033",
  },
  FinaliseMoney: {
    textAlign: "right",
  },
  Line: {
    textAlign: "right",
    marginBottom: 5,
    marginTop: 5,
  },
};

export default budgetSectionStyle;
