import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import incomeBudgetFieldsStyle from "../../assets/jss/xrs/views/incomeBudgetFieldsStyle";
import GridContainer from "components/Grid/GridContainer.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/CustomButtons/Button";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import TextField from "../../components/CustomInput/CustomInput.js";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloseIcon from "@material-ui/icons/Close";
import { Collapse } from "@material-ui/core";
import DocumentSection from "../SectionsPage/Sections/DocumentSection.js";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";

import {
  cameraActions,
  documentsActions,
} from "../../store/redux/redux-actions/redux_actions";
import { isMobile } from "react-device-detect";
import { element } from "prop-types";
let disabledCamera = !isMobile;

const useStyles = makeStyles(incomeBudgetFieldsStyle);
export default function IncomeBudgetFields(props) {
  const classes = useStyles();
  const [selected, setSelected] = React.useState(props.selectedvalue);
  const [value, setValue] = React.useState(props.value);
  // const openDocumet = useSelector((state) => state.DocumentsReducer.open);
  const [openDocumet, setOpenDocumet] = React.useState(false);

  // const uploadedExpenses = useSelector(
  //   (state) => state.DocumentsReducer.uploadedExpenses
  // );
  // const uploadedIncomes = useSelector(
  //   (state) => state.DocumentsReducer.uploadedIncomes
  // );
  const documents = useSelector((state) => state.CameraReducer.confirmedImages);
  const [uploadedDocumet, setUploadedDocumet] = React.useState("");
  const [cameraExist, setcameraExist] = React.useState(false);
  const dispatch = useDispatch();
  const uploadimagestobudget = useSelector(
    (state) => state.GetSiteDetailsReducer.uploadimagestobudget
  );
  React.useEffect(() => {
    if (uploadimagestobudget && isMobile) {
      navigator.mediaDevices.getUserMedia({ video: true });
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        var amount = 0;
        devices.map((item) => {
          if (item.kind === "videoinput") {
            amount++;
          }
        });
        if (amount > 0) {
          setcameraExist(false);
        } else {
          setcameraExist(true);
        }
      });
    }
  }, []);
  React.useEffect(() => {
    if (documents.length > 0) {
      if (props.incomeId) {
        let exists = documents.find(
          (element) =>
            element.from == props.from && element.itemId == props.incomeId
        );

        if (exists) {
          setUploadedDocumet(exists);
          // setOpenDocumet(!openDocumet);
        } else {
          setUploadedDocumet("");
        }
      }
      if (props.expenseId) {
        let exists = documents.find(
          (element) =>
            element.from == props.from && element.itemId == props.expenseId
        );

        if (exists) {
          setUploadedDocumet(exists);
          // setOpenDocumet(!openDocumet);
        } else {
          setUploadedDocumet("");
        }
      }
    } else {
      setUploadedDocumet("");
    }
  }, [documents]);

  const handleEvent = (event) => {
    if (event.target.value < 0) {
      setValue(0);
    } else {
      setValue(event.target.value);
    }
    props.onhandleChange(event);
  };
  const selectedhandleEvent = (event) => {
    setSelected(event.target.value);
    props.handleSelectedChange(event);
  };
  const onClick = () => {
    setOpenDocumet(!openDocumet);
  };
  var elem = document.documentElement;

  function openFullscreen() {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  }
  const onOpenCamera = () => {
    openFullscreen();

    dispatch(
      cameraActions.openCamera({
        from: props.from,
        name: props.name,
        itemId:
          props.expenseId !== undefined ? props.expenseId : props.incomeId,
      })
    );
  };
  const onViewDocument = () => {
    let documentToShow;
    let pdf;
    if (uploadedDocumet.name === undefined) {
      pdf = true;
      documentToShow = uploadedDocumet.document.preview;
    } else {
      pdf = false;

      documentToShow = uploadedDocumet.preview;
    }
    if (pdf && isMobile) {
      window.open(documentToShow, "_blank");
    } else {
      dispatch(documentsActions.viewDocumet(uploadedDocumet));
      openFullscreen();
    }
  };
  const onDeleteDocument = () => {
    dispatch(cameraActions.deleteDocument(uploadedDocumet));
    setUploadedDocumet("");
  };
  return (
    <div>
      <Collapse in={openDocumet}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={2}>
            <p>{props.name}</p>
          </GridItem>

          <GridItem xs={12} sm={12} md={7}>
            <DocumentSection
              item={{
                from: props.from,
                itemId:
                  props.expenseId !== undefined
                    ? props.expenseId
                    : props.incomeId,
              }}
              uploadedDocument={uploadedDocumet}
            ></DocumentSection>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <GridContainer>
              {isMobile ? (
                <GridItem
                  style={
                    isMobile ? { textAlign: "right" } : { textAlign: "left" }
                  }
                  xs={4}
                  sm={4}
                  md={4}
                ></GridItem>
              ) : null}
              {isMobile ? (
                <GridItem
                  style={
                    isMobile ? { textAlign: "right" } : { textAlign: "left" }
                  }
                  xs={4}
                  sm={4}
                  md={4}
                >
                  <Button
                    style={{
                      padding: "13px",
                      margin: 0,
                      display: "inline",
                      marginRight: 10,
                    }}
                    color="primary"
                    onClick={onOpenCamera}
                    title="Open camera"
                    disabled={cameraExist}
                  >
                    <PhotoCameraIcon
                      style={{ padding: 0, margin: 0 }}
                    ></PhotoCameraIcon>
                  </Button>
                </GridItem>
              ) : null}
              <GridItem
                style={
                  isMobile ? { textAlign: "right" } : { textAlign: "left" }
                }
                xs={4}
                sm={4}
                md={4}
              >
                <Button
                  style={{ padding: "13px", margin: 0, marginRight: 10 }}
                  color="primary"
                  onClick={onClick}
                  title="Back"
                >
                  <CloseIcon style={{ padding: 0, margin: 0 }}></CloseIcon>
                </Button>
              </GridItem>
              {!isMobile ? (
                <GridItem
                  style={
                    isMobile ? { textAlign: "right" } : { textAlign: "left" }
                  }
                  xs={4}
                  sm={4}
                  md={4}
                ></GridItem>
              ) : null}
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Collapse>
      <Collapse in={!openDocumet}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={2}>
            <p>{props.name}</p>
          </GridItem>

          <GridItem xs={12} sm={12} md={4}>
            <TextField
              className={classes.control}
              showCurrency
              inputProps={{
                type: "number",

                value: value,
                step: 0.01,
                min: 0,
                name: props.name,
                onChange: (e) => {
                  handleEvent(e);
                },
              }}
              formControlProps={{
                fullWidth: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Select
              className={classes.controlWhite}
              onChange={(e) => {
                selectedhandleEvent(e);
              }}
              value={selected}
              fullWidth
              id="demo-customized-select"
            >
              <MenuItem value="Monthly">Monthly</MenuItem>
              <MenuItem value="Weekly">Weekly</MenuItem>
            </Select>
          </GridItem>
          <GridItem
            style={
              isMobile
                ? { textAlign: "right", marginTop: "20px" }
                : { textAlign: "left" }
            }
            xs={12}
            sm={12}
            md={3}
          >
            {props.canAttachFile && uploadedDocumet == "" ? (
              <Button
                style={{
                  padding: "13px",
                  margin: 0,
                  marginRight: 10,
                }}
                color="primary"
                onClick={onClick}
                title="Attach file"
              >
                <AttachFileIcon
                  style={{ padding: 0, margin: 0 }}
                ></AttachFileIcon>
              </Button>
            ) : uploadedDocumet != "" ? (
              <GridContainer>
                <GridItem
                  style={
                    isMobile ? { textAlign: "right" } : { textAlign: "left" }
                  }
                  xs={4}
                  sm={4}
                  md={4}
                >
                  <Button
                    style={{
                      padding: "13px",
                      margin: 0,
                      marginRight: 10,
                    }}
                    color="primary"
                    onClick={onClick}
                    title="Attach new and replace file"
                  >
                    <AttachFileIcon
                      style={{ padding: 0, margin: 0 }}
                    ></AttachFileIcon>
                  </Button>
                </GridItem>
                <GridItem
                  style={
                    isMobile ? { textAlign: "right" } : { textAlign: "left" }
                  }
                  xs={4}
                  sm={4}
                  md={4}
                >
                  <Button
                    style={{
                      padding: "13px",
                      margin: 0,
                      display: "inline",
                      marginRight: 10,
                    }}
                    title="View file"
                    color="primary"
                    onClick={onViewDocument}
                  >
                    <VisibilityIcon style={{ padding: 0, margin: 0 }} />
                  </Button>
                </GridItem>
                <GridItem
                  style={
                    isMobile ? { textAlign: "right" } : { textAlign: "left" }
                  }
                  xs={4}
                  sm={4}
                  md={4}
                >
                  <Button
                    style={{
                      padding: "13px",
                      margin: 0,
                      display: "inline",
                      marginRight: 10,
                    }}
                    title="Delete file"
                    color="primary"
                    onClick={onDeleteDocument}
                  >
                    <DeleteIcon style={{ padding: 0, margin: 0 }} />
                  </Button>
                </GridItem>
              </GridContainer>
            ) : null}
          </GridItem>
        </GridContainer>
      </Collapse>
    </div>
  );
}
