import { Collapse } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import viewOfferSectionsStyle from "assets/jss/xrs/views/sectionsSections/viewOfferSectionsStyle";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useContext } from "react";
import { isMobile } from "react-device-detect";
import NumberFormat from "react-number-format";
import Context from "../../../utils/context";
import Paynow from "../../SectionsPage/Sections/PayNowSection.js";
import BlueHeader from "./BlueHeaderSection.js";
import { useDispatch, useSelector } from "react-redux";
import { primaryColor } from "assets/jss/xrs.js";

let drawerWidth = 150;
if (isMobile) {
  drawerWidth = 0;
}

const useStyles = makeStyles(viewOfferSectionsStyle);
export default function ViewOfferSection(props) {
  const viewOfferText = useSelector((state) => state.HtmlReducer.viewOfferText);

  const context = useContext(Context);
  const classes = useStyles();
  const [site, setSite] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [termsVisible, setTermsVisible] = React.useState(false);
  const [width, setWidth] = React.useState(0);
  const [message, setMessage] = React.useState("");
  const [showDate, setShowDate] = React.useState(true);
  const [oneMonth, setoneMonth] = React.useState(false);

  const [paymentDate, setPaymentDate] = React.useState("");
  const [screenstate, setScreenstate] = React.useState({ screenshot: "" });
  const [showQuickPay, setShowQuickPay] = React.useState(true);
  const [ifamevisible, setIfamevisible] = React.useState(false);
  const [value, setValue] = React.useState("card");
  const stateOffer = useSelector((state) => state.OfferReducer.offer);
  const stateSite = useSelector((state) => state.SiteReducer.site);
  const currency = useSelector((state) => state.GetSiteDetailsReducer.currency);

  const [refValues, setrefValues] = React.useState([]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const values = {
    OFFERID: 0,
    DOWNPAYMENT: 0,
    REMAINGBAL: 1,
    OFFERACCEPTEDDECLINED: "A",
    OFFERDECLINEDREASON: "OK",
    STARTDATE: "",
    FREQUENCY: "MONTHLY",
    TERM: 1,
    AMOUNT: 0,
    FINALDATE: "",
    FINALAMOUNT: 0,
    APPLYDISCOUNT: "Y",
    DISCOUNTOPTIONNUMBER: "0",
    SCREENSHOT: "",
    PAYERREF: "",
    CARDREF: "",
    MAXPAYDATE: "",
  };

  const setValues = (termsChosen) => {
    values.MAXPAYDATE = stateOffer.WOOFFERMAXFIRSTPAYDATE;
    if (termsChosen == 1) {
      values.REMAINGBAL =
        stateOffer.WOOFFER1MONTHS === 1 ? 0 : stateOffer.WOOFFER1TOTALPAYABLE;
      values.OFFERID = stateOffer.WOOFFERID;
      values.DOWNPAYMENT = stateOffer.WOOFFER1MINPAY;
      //values.FREQUENCY =stateOffer.WOOFFER1MONTHS;
      values.AMOUNT = stateOffer.WOOFFER1AMOUNT;
      values.FINALDATE = "";
      values.FINALAMOUNT = 0;
      values.DISCOUNTOPTIONNUMBER = 1;
      values.TERM = stateOffer.WOOFFER1MONTHS;
      if (stateOffer.WOOFFER1MONTHS === 1) {
        setoneMonth(true);
      }
    } else if (termsChosen == 2) {
      values.REMAINGBAL =
        stateOffer.WOOFFER2MONTHS === 1 ? 0 : stateOffer.WOOFFER2TOTALPAYABLE;
      values.TERM = stateOffer.WOOFFER2MONTHS;

      values.OFFERID = stateOffer.WOOFFERID;
      values.DOWNPAYMENT = stateOffer.WOOFFER2MINPAY;
      // values.FREQUENCY =stateOffer.WOOFFER2MONTHS;
      values.AMOUNT =
        stateOffer.WOOFFER2MONTHS === 1 ? 0 : stateOffer.WOOFFER2AMOUNT;
      values.FINALDATE = "";
      values.FINALAMOUNT = 0;
      values.DISCOUNTOPTIONNUMBER = 2;
      if (stateOffer.WOOFFER2MONTHS === 1) {
        setoneMonth(true);
      }
    } else if (termsChosen == 3) {
      values.REMAINGBAL =
        stateOffer.WOOFFER3MONTHS === 1 ? 0 : stateOffer.WOOFFER3TOTALPAYABLE;
      values.TERM = stateOffer.WOOFFER3MONTHS;

      values.OFFERID = stateOffer.WOOFFERID;
      values.DOWNPAYMENT =
        stateOffer.WOOFFER3MONTHS === 1
          ? stateOffer.WOOFFER3MINPAY
          : stateOffer.WOOFFER3AMOUNT; //  values.FREQUENCY =stateOffer.WOOFFER3MONTHS;
      values.AMOUNT =
        stateOffer.WOOFFER3MONTHS === 1 ? 0 : stateOffer.WOOFFER3AMOUNT;
      values.FINALDATE = "";
      values.FINALAMOUNT = 0;
      values.DISCOUNTOPTIONNUMBER = 3;
      if (stateOffer.WOOFFER3MONTHS === 1) {
        setoneMonth(true);
      }
    }
  };

  function handleClickOffer1(e) {
    setValues(1);
    setrefValues(values);
    if (stateOffer.WOOFFER1MONTHS === 1) {
      var text =
        "I agree to pay " +
        site.companyname +
        " " +
        currency +
        Number.parseFloat(stateOffer.WOOFFER1MINPAY).toFixed("2") +
        " from my card now ";
      setMessage(text);
      setShowDate(false);
      setTermsVisible(true);
    } else {
      var text =
        "I agree to pay " +
        site.companyname +
        " " +
        currency +
        Number.parseFloat(stateOffer.WOOFFER1MINPAY).toFixed("2") +
        " from my card now, followed by " +
        stateOffer.WOOFFER1MONTHS +
        " recurring monthly payments of " +
        currency +
        stateOffer.WOOFFER1AMOUNT +
        " and starting on ";
      setMessage(text);
      setTermsVisible(true);
    }
  }
  function handleClickOffer2(e) {
    setValues(2);
    setrefValues(values);

    if (stateOffer.WOOFFER2MONTHS === 1) {
      var text =
        "I agree to pay " +
        site.companyname +
        " " +
        currency +
        Number.parseFloat(stateOffer.WOOFFER2MINPAY).toFixed("2") +
        " from my card now ";
      setMessage(text);
      setShowDate(false);

      setTermsVisible(true);
    } else {
      var text =
        "I agree to pay " +
        site.companyname +
        " " +
        currency +
        Number.parseFloat(stateOffer.WOOFFER2MINPAY).toFixed("2") +
        " from my card now, followed by " +
        stateOffer.WOOFFER2MONTHS +
        " recurring monthly payments of " +
        currency +
        stateOffer.WOOFFER2AMOUNT +
        " and starting on ";
      setMessage(text);
      setTermsVisible(true);
    }
  }
  function handleClickOffer3(e) {
    setValues(3);
    setrefValues(values);

    if (stateOffer.WOOFFER3MONTHS === 1) {
      var text =
        "I agree to pay " +
        site.companyname +
        " " +
        currency +
        Number.parseFloat(stateOffer.WOOFFER3MINPAY).toFixed("2") +
        " from my card now ";
      setMessage(text);
      setShowDate(false);

      setTermsVisible(true);
    } else {
      var text =
        "I agree to pay " +
        site.companyname +
        " " +
        currency +
        Number.parseFloat(stateOffer.WOOFFER3MINPAY).toFixed("2") +
        " from my card now, followed by " +
        stateOffer.WOOFFER3MONTHS +
        " recurring monthly payments of " +
        currency +
        stateOffer.WOOFFER3AMOUNT +
        " and starting on ";
      setMessage(text);
      setTermsVisible(true);
    }
  }
  function handleBackToOffers(e) {
    setTermsVisible(false);
  }
  const handlePayDateChange = (e) => {
    setPaymentDate(e.target.value);
  };

  React.useEffect(() => {
    setSite(stateSite);
  }, []);
  return (
    <div>
      <BlueHeader></BlueHeader>

      <Collapse in={!termsVisible}>
        <Card style={{ backgroundColor: primaryColor[19], margin: 0 }}>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                {viewOfferText}
              </GridItem>
              {stateOffer.WOOFFER1MONTHS === 0 ? null : (
                <GridItem xs={12} sm={12} md={4}>
                  <Paper
                    variant="outlined"
                    elevation={stateOffer.WOOFFER1MONTHS === 1 ? "10" : "1"}
                  >
                    <Paper
                      variant="outlined"
                      className={
                        stateOffer.WOOFFER1MONTHS === 1
                          ? classes.green
                          : classes.blue
                      }
                    >
                      {stateOffer.WOOFFER1TEXT}
                    </Paper>
                    <Paper elevation={0} className={classes.blueBox}>
                      <p className={classes.DiscountBlue}>
                        {stateOffer.WOOFFER1DISCOUNTPERCENT + " % Discount"}
                      </p>
                      <p className={classes.DiscountItem}>
                        Total To Pay:
                        <NumberFormat
                          value={stateOffer.WOOFFER1TOTALPAYABLE}
                          displayType={"text"}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          decimalScale={2}
                          prefix={currency}
                        />
                      </p>

                      <p className={classes.DiscountItem}>
                        Discount Value:
                        <NumberFormat
                          value={stateOffer.WOOFFER1DISCOUNTVALUE}
                          displayType={"text"}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          decimalScale={2}
                          prefix={currency}
                        />
                      </p>

                      <p className={classes.DiscountItem}>
                        {stateOffer.WOOFFER1MONTHS === 1
                          ? "One payment"
                          : "Term: " + stateOffer.WOOFFER1MONTHS + " Months"}
                      </p>

                      <p className={classes.DiscountItem}>
                        Down Payment:
                        <NumberFormat
                          value={stateOffer.WOOFFER1MINPAY}
                          displayType={"text"}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          decimalScale={2}
                          prefix={currency}
                        />
                      </p>

                      <p className={classes.DiscountItem}>
                        {stateOffer.WOOFFER1MONTHS === 1
                          ? "Recurring Amount:"
                          : "Payment Amount:"}
                        <NumberFormat
                          value={
                            stateOffer.WOOFFER1MONTHS === 1
                              ? 0
                              : stateOffer.WOOFFER1AMOUNT
                          }
                          displayType={"text"}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          decimalScale={2}
                          prefix={currency}
                        />
                      </p>
                      <div className={classes.textButton}>
                        <GridItem xs={12} sm={12} md={12}>
                          <Button
                            className={classes.textButton}
                            onClick={handleClickOffer1}
                            color={
                              stateOffer.WOOFFER1MONTHS === 1
                                ? "success"
                                : "primary"
                            }
                            size="lg"
                          >
                            Proceed!
                          </Button>
                        </GridItem>
                      </div>
                    </Paper>
                  </Paper>
                </GridItem>
              )}
              {stateOffer.WOOFFER2MONTHS === 0 ? null : (
                <GridItem xs={12} sm={12} md={4}>
                  <Paper
                    variant="outlined"
                    elevation={stateOffer.WOOFFER2MONTHS === 1 ? "10" : "1"}
                  >
                    <Paper
                      variant="outlined"
                      className={
                        stateOffer.WOOFFER2MONTHS === 1
                          ? classes.green
                          : classes.blue
                      }
                    >
                      {stateOffer.WOOFFER2TEXT}
                    </Paper>
                    <Paper elevation={0} className={classes.blueBox}>
                      <p className={classes.DiscountBlue}>
                        {stateOffer.WOOFFER2DISCOUNTPERCENT + " % Discount"}
                      </p>
                      <p className={classes.DiscountItem}>
                        Total To Pay:
                        <NumberFormat
                          value={stateOffer.WOOFFER2TOTALPAYABLE}
                          displayType={"text"}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          decimalScale={2}
                          prefix={currency}
                        />
                      </p>

                      <p className={classes.DiscountItem}>
                        Discount Value:
                        <NumberFormat
                          value={stateOffer.WOOFFER2DISCOUNTVALUE}
                          displayType={"text"}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          decimalScale={2}
                          prefix={currency}
                        />
                      </p>

                      <p className={classes.DiscountItem}>
                        {stateOffer.WOOFFER2MONTHS === 1
                          ? "One payment"
                          : "Term: " + stateOffer.WOOFFER2MONTHS + " Months"}
                      </p>

                      <p className={classes.DiscountItem}>
                        Down Payment:
                        <NumberFormat
                          value={stateOffer.WOOFFER2MINPAY}
                          displayType={"text"}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          decimalScale={2}
                          prefix={currency}
                        />
                      </p>

                      <p className={classes.DiscountItem}>
                        {stateOffer.WOOFFER2MONTHS === 1
                          ? "Recurring Amount:"
                          : "Payment Amount:"}
                        <NumberFormat
                          value={
                            stateOffer.WOOFFER2MONTHS === 1
                              ? 0
                              : stateOffer.WOOFFER2AMOUNT
                          }
                          displayType={"text"}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          decimalScale={2}
                          prefix={currency}
                        />
                      </p>
                      <div className={classes.textButton}>
                        <GridItem xs={12} sm={12} md={12}>
                          <Button
                            className={classes.textButton}
                            onClick={handleClickOffer2}
                            color={
                              stateOffer.WOOFFER2MONTHS === 1
                                ? "success"
                                : "primary"
                            }
                            size="lg"
                          >
                            Proceed!
                          </Button>
                        </GridItem>
                      </div>
                    </Paper>
                  </Paper>
                </GridItem>
              )}
              {stateOffer.WOOFFER3MONTHS === 0 ? null : (
                <GridItem xs={12} sm={12} md={4}>
                  <Paper
                    variant="outlined"
                    elevation={stateOffer.WOOFFER3MONTHS === 1 ? "10" : "1"}
                  >
                    <Paper
                      variant="outlined"
                      className={
                        stateOffer.WOOFFER3MONTHS === 1
                          ? classes.green
                          : classes.blue
                      }
                    >
                      {stateOffer.WOOFFER3TEXT}
                    </Paper>
                    <Paper elevation={0} className={classes.blueBox}>
                      <p className={classes.DiscountBlue}>
                        {stateOffer.WOOFFER3DISCOUNTPERCENT + " % Discount"}
                      </p>
                      <p className={classes.DiscountItem}>
                        Total To Pay:
                        <NumberFormat
                          value={stateOffer.WOOFFER3TOTALPAYABLE}
                          displayType={"text"}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          decimalScale={2}
                          prefix={currency}
                        />
                      </p>

                      <p className={classes.DiscountItem}>
                        Discount Value:
                        <NumberFormat
                          value={stateOffer.WOOFFER3DISCOUNTVALUE}
                          displayType={"text"}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          decimalScale={2}
                          prefix={currency}
                        />
                      </p>

                      <p className={classes.DiscountItem}>
                        {stateOffer.WOOFFER3MONTHS === 1
                          ? "One payment"
                          : "Term: " + stateOffer.WOOFFER3MONTHS + " Months"}
                      </p>

                      <p className={classes.DiscountItem}>
                        Down Payment:
                        <NumberFormat
                          value={stateOffer.WOOFFER3MINPAY}
                          displayType={"text"}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          decimalScale={2}
                          prefix={currency}
                        />
                      </p>

                      <p className={classes.DiscountItem}>
                        {stateOffer.WOOFFER3MONTHS === 1
                          ? "Recurring Amount:"
                          : "Payment Amount:"}{" "}
                        <NumberFormat
                          value={
                            stateOffer.WOOFFER3MONTHS === 1
                              ? 0
                              : stateOffer.WOOFFER3AMOUNT
                          }
                          displayType={"text"}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          decimalScale={2}
                          prefix={currency}
                        />
                      </p>
                      <div className={classes.textButton}>
                        <GridItem xs={12} sm={12} md={12}>
                          <Button
                            className={classes.textButton}
                            onClick={handleClickOffer3}
                            color={
                              stateOffer.WOOFFER3MONTHS === 1
                                ? "success"
                                : "primary"
                            }
                            size="lg"
                          >
                            Proceed!
                          </Button>
                        </GridItem>
                      </div>
                    </Paper>
                  </Paper>
                </GridItem>
              )}
            </GridContainer>
          </CardBody>
        </Card>
      </Collapse>

      <Collapse in={termsVisible}>
        {termsVisible ? (
          <Paynow
            value={refValues}
            source="ViewOffer"
            showdate={showDate}
            enabled="false"
            message={message}
            oneMonth={oneMonth}
          ></Paynow>
        ) : null}
      </Collapse>
    </div>
  );
}
