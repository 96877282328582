import React, { useContext } from "react";

import history from "./utils/history";
import Context from "./utils/context";
import MomentUtils from "@date-io/moment";

import { Router, Route, Switch, Redirect } from "react-router";

import "assets/scss/xrs.scss";
// pages for this product
import WellcomePage from "views/WellcomePage/WellcomePage.js";
import DocumentsPage from "views/DocumentsPage/DocumentsPage.js";

import ViewAccountPage from "views/ViewAccountPage/ViewAccountPage.js";
import CreatePayment from "views/CreatePaymentPage/CreatePaymentPlanPage.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import LandingPageCreateUser from "views/LandingPage/LandingPageCreateUser.js";
import QuickPayPage from "views/QuickPayPage/QuickPayPage.js";
import QuickPayPaymentStatus from "views/QuickPayPage/QuickPayPaymentStatus.js";
import PayNowPaymentStatus from "views/PayNowPage/PayNowPaymentStatus.js";
import PayNowPage from "views/PayNowPage/PayNowPage.js";
import ViewOfferPage from "views/ViewOfferPage/ViewOfferPage.js";
import FaqPage from "views/FaqPage/FaqPage.js";
import BudgetPage from "views/BudgetPage/BudgetPage.js";
import RequestCallBackPage from "views/RequestCallBackPage/RequestCallBackPageLoggendIn.js";
import ChangePassword from "views/ChangePassworOrPin/ChangePasswordOrPinPage.js";
import faq from "views/FaqPageLogin/FaqPageLogin.js";
import IframePage from "views/IframePage/IframePage";

import MessagesPage from "views/MessagesPage/MessagesPage.js";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { changePassword } from "utils/XRSAPIs";

const PrivateRoute = ({ component: Component, auth }) => (
  <Route
    render={(props) =>
      auth === true ? (
        <Component auth={auth} {...props} />
      ) : (
        <Redirect to={{ pathname: "/" }} />
      )
    }
  />
);

const Routes = () => {
  const context = useContext(Context);
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Router history={history}>
        <Switch>
          <PrivateRoute
            path="/privateroute"
            auth={context.authState}
            component={WellcomePage}
          />
          <Route path="/welcome" component={WellcomePage} />
          <Route path="/documents" component={DocumentsPage} />

          <Route path="/createPayment" component={CreatePayment} />
          <Route
            path="/quickpay-paymentstatus"
            component={QuickPayPaymentStatus}
          />

          <Route path="/paynow-paymentstatus" component={PayNowPaymentStatus} />
          <Route path="/faq" component={faq} />
          <Route path="/messages" component={MessagesPage} />
          <Route path="/payNow" component={PayNowPage} />
          <Route path="/iframe" component={IframePage} />

          <Route
            key="password"
            path="/changePassword"
            render={(props) => <ChangePassword {...props} cred="password" />}
          />

          <Route
            key="pin"
            path="/changePin"
            render={(props) => <ChangePassword {...props} cred="pin" />}
          />

          <Route path="/viewaccount" component={ViewAccountPage} />
          <Route path="/budgetPage" component={BudgetPage} />
          <Route path="/createUser" component={LandingPageCreateUser} />
          <Route
            key="landing"
            path="/landing-page"
            render={(props) => <LandingPage {...props} create="false" />}
          />
          <Route
            key="create"
            path="/CreateAccount"
            render={(props) => <LandingPage {...props} create="true" />}
          />
          <Route
            path="/quickpay-page"
            render={(props) => <QuickPayPage {...props} />}
          />
          <Route path="/requestcallback-page" component={RequestCallBackPage} />
          <Route path="/faq-page" component={FaqPage} />
          <Route path="/viewOffer" component={ViewOfferPage} />

          <Route
            path="/"
            render={(props) => <LandingPage {...props} create="false" />}
          />
        </Switch>
      </Router>
    </MuiPickersUtilsProvider>
  );
};
export default Routes;
