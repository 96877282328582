import React, { useContext } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import documentsPageStyle from "../../assets/jss/xrs/views/documentsPageStyle";
import { primaryColor } from "assets/jss/xrs.js";

import { makeStyles } from "@material-ui/core/styles";
import landingPageStyle from "assets/jss/xrs/views/landingPageStyle.js";
import HeaderSectionLoggedID from "../SectionsPage/Sections/HeaderSectionLoggedID.js";
import DocumentSection from "../SectionsPage/Sections/DocumentSection.js";
import Context from "../../utils/context";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import clsx from "clsx";
import * as URLS from "../../utils/XRSAPIs";
import { isMobile } from "react-device-detect";
const useStyles = makeStyles(landingPageStyle);
let drawerWidth = 150;
if (isMobile) {
  drawerWidth = 0;
}
const useStyles2 = makeStyles(documentsPageStyle);

export default function DocumentsPage(props) {
  const context = useContext(Context);
  const openCamera = useSelector((state) => state.CameraReducer.open);

  const classes = useStyles();
  const classes2 = useStyles2();
  const [page, setPage] = React.useState(1);

  const token = sessionStorage.getItem("access_token");
  if (openCamera) {
    return <DocumentSection></DocumentSection>;
  }
  return (
    <div style={{ backgroundColor: primaryColor[14] }}>
      <HeaderSectionLoggedID
        page="Documents"
        title="Documents"
      ></HeaderSectionLoggedID>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <main
            className={clsx(classes2.content, {
              [classes2.contentShift]: context.stateDrawer,
            })}
          >
            <DocumentSection></DocumentSection>
          </main>
        </GridItem>
      </GridContainer>
    </div>
  );
}
