import * as ACTION_TYPES from "../actions/action_types";

export const initialState = {
  accounts: "NoAccounts",
  numOfAccounts: 0,
  registrationMethod: null,
};

export const AccountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload,
      };
    case ACTION_TYPES.REGISTRTION_METHOD:
      return {
        ...state,
        registrationMethod: action.payload,
      };
    case ACTION_TYPES.SET_NUMACCOUNTS:
      return {
        ...state,
        numOfAccounts: action.payload,
      };
    case ACTION_TYPES.FAILURE:
      return {
        ...state,
        accounts: "FAILURE",
      };
    default:
      return state;
  }
};
