import { CAMERA_ACTION_TYPES } from "../redux-actions/redux_action_types";

export const initialState = {
  openFrom: "",
  capturedImage: "",
  confirmedImages: [],
};

export const CameraReducer = (state = initialState, action) => {
  switch (action.type) {
    case CAMERA_ACTION_TYPES.OPEN_CAMERA:
      return {
        ...state,
        openFrom: action.payload,
      };
    case CAMERA_ACTION_TYPES.SET_IMAGE:
      return {
        ...state,
        capturedImage: action.payload,
      };
    case CAMERA_ACTION_TYPES.REMOVE_CONFIRMED_IMAGE:
      return {
        ...state,
        confirmedImages: state.confirmedImages.filter(
          (item) =>
            item.itemId !== action.payload.itemId &&
            item.from === action.payload.from
        ),
      };
    case CAMERA_ACTION_TYPES.ADD_CONFIRMED_IMAGE:
      return {
        ...state,
        confirmedImages: [...state.confirmedImages, action.payload],
      };
    case CAMERA_ACTION_TYPES.RESET_CONFIRMET_IMAGES:
      return {
        ...state,
        confirmedImages: [],
      };
    default:
      return state;
  }
};
