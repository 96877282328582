export const SUCCESS = "SUCCESS";
export const IFRAME_URL = "IFRAME_URL";

export const FAILURE = "FAILURE";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const ADD_PROFILE = "ADD_PROFILE";

export const REMOVE_PROFILE = "REMOVE_PROFILE";

export const USER_INPUT_CHANGE = "USER_INPUT_CHANGE";

export const USER_INPUT_SUBMIT = "USER_INPUT_SUBMIT";

export const SET_CUSTOMER = "SET_CUSTOMER";

export const SET_ACCOUNTS = "SET_ACCOUNTS";

export const REGISTRTION_METHOD = "REGISTRTION_METHOD";

export const SET_CALLBACKS = "SET_CALLBACKS";

export const SET_SITE = "SET_SITE";

export const SET_OFFER = "SET_OFFER";

export const SET_MENU = "SET_MENU";

export const SET_DRAWER = "SET_DRAWER";

export const SET_DRAWER1 = "SET_DRAWER1";

export const SET_DRAWER2 = "SET_DRAWER2";

export const SET_DRAWER3 = "SET_DRAWER3";

export const SET_NUMACCOUNTS = "SET_NUMACCOUNTS";

export const SET_SELECTEDACCOUNT = "SET_SELECTEDACCOUNT";

export const SET_SESSIONTIMEOUT = "SET_SESSIONTIMEOUT";

export const SET_OVERALLTIMEOUT = "SET_OVERALLTIMEOUT";

export const SET_STARTOVERALLTIMEOUT = "SET_STARTOVERALLTIMEOUT";

export const PAYMENT_STATUS_FORM = "PAYMENT_STATUS_FORM";
