/*eslint-disable*/ import React from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import landingPageStyle from "assets/jss/xrs/views/landingPageStyle.js";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(landingPageStyle);

export default function LinksSection({ ...rest }) {
  const classes = useStyles();
  return (
    <div
      className={classNames(classes.main, classes.mainRaised, classes.paper)}
    >
      <div className={classes.container}>
        <GridContainer
          direction="row"
          justify="space-evenly"
          alignItems="stretch"
        >
          <GridItem xs={12} sm={6} md={4}>
            <Link to="quickpay-page">
              <Button className={classes.linkButton} round color="primary">
                <img
                  src={require("../../../assets/img/ico-quick-pay.png")}
                  alt="..."
                  height="45"
                />
              </Button>
            </Link>

            <h4 className={classNames(classes.textCenter)}>Quick Pay</h4>
          </GridItem>

          <GridItem xs={12} sm={6} md={4}>
            <Link to="createUser">
              <Button className={classes.linkButton} round color="primary">
                <img
                  src={require("../../../assets/img/ico-register.png")}
                  alt="..."
                  height="45"
                  className={classes.img}
                />
              </Button>
            </Link>

            <h4 className={classNames(classes.textCenter)}>Register</h4>
          </GridItem>

          <GridItem xs={12} sm={6} md={4}>
            <Link to="faq-page">
              <Button className={classes.linkButton} round color="primary">
                <img
                  src={require("../../../assets/img/ico-faq.png")}
                  alt="..."
                  height="45"
                  className={classes.img}
                />
              </Button>
            </Link>
            <h4 className={classNames(classes.textCenter)}>
              Frequently Asked Questions
            </h4>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
