import React, { useContext } from "react";
import classNames from "classnames";
import createPaymentPlanPageStyle from "../../assets/jss/xrs/views/createPaymentPlanPageStyle";
import { makeStyles } from "@material-ui/core/styles";
import landingPageStyle from "assets/jss/xrs/views/landingPageStyle.js";
import HeaderSectionLoggedID from "../SectionsPage/Sections/HeaderSectionLoggedID.js";
import WizardSection from "../SectionsPage/Sections/WizardSection.js";
import Context from "../../utils/context";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import clsx from "clsx";
import * as URLS from "../../utils/XRSAPIs";
import { isMobile } from "react-device-detect";
import { primaryColor } from "assets/jss/xrs.js";

const useStyles = makeStyles(landingPageStyle);
let drawerWidth = 150;
if (isMobile) {
  drawerWidth = 0;
}
const useStyles2 = makeStyles(createPaymentPlanPageStyle);
export default function LandingPage(props) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  const context = useContext(Context);
  const classes2 = useStyles2();
  const classes = useStyles();
  const [page, setPage] = React.useState(1);

  const token = sessionStorage.getItem("access_token");

  // can be removed dev only

  return (
    <div style={{ backgroundColor: primaryColor[14] }}>
      <HeaderSectionLoggedID
        page="createPayment"
        title="Create payment plan"
      ></HeaderSectionLoggedID>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <main
            className={clsx(classes2.content, {
              [classes2.contentShift]: context.stateDrawer,
            })}
          >
            <WizardSection></WizardSection>
          </main>
        </GridItem>
      </GridContainer>
    </div>
  );
}
