import { combineReducers } from "redux";
import { ApiCallsReducer } from "./apiCalls_reducer";
import { GetSiteDetailsReducer } from "./getSiteDetailsReducer";
import { HtmlReducer } from "./html_reducer";
import { TimeOutReducer } from "../../reducers/timeout_reducer";
import { SiteReducer } from "../../reducers/site_reducer";
import { SelectedAccountsReducer } from "../../reducers/selectedAccounts_reducer";
import { Reducer1 } from "../../reducers/plain_reducer";
import { OfferReducer } from "../../reducers/offer_reducer";
import { MenuReducer } from "../../reducers/menu_reducer";
import { FormReducer } from "../../reducers/form_reducer";
import { DrawerReducer } from "../../reducers/drawer_reducer";
import { CustomerReducer } from "../../reducers/customer_reducer";
import { CallbacksReducer } from "../../reducers/callbacks_reducer";
import { AuthReducer } from "../../reducers/auth_reducer";
import { AccountsReducer } from "../../reducers/accounts_reducer";
import { CameraReducer } from "../redux-reducers/camera_reducer";
import { DocumentsReducer } from "../redux-reducers/documents_reducer";
import { RESET_STORE } from "../redux-actions/redux_action_types";

const appReducer = combineReducers({
  ApiCallsReducer,
  GetSiteDetailsReducer,
  HtmlReducer,
  TimeOutReducer,
  SiteReducer,
  SelectedAccountsReducer,
  Reducer1,
  OfferReducer,
  MenuReducer,
  FormReducer,
  CustomerReducer,
  CallbacksReducer,
  AuthReducer,
  AccountsReducer,
  CameraReducer,
  DocumentsReducer,
});
const rootReducer = (state, action) => {
  if (action.type === RESET_STORE) {
    state = undefined;
  }
  return appReducer(state, action);
};
export default rootReducer;
