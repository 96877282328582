export const API_ACTION_TYPES = {
  ERROR: "ERROR",
  LOADING: "LOADING",
  ERROR_MESSAGE: "ERROR_MESSAGE",
  CLEAR: "CLEAR",
  CODE: "CODE",
};

export const GET_SITE_DETAILS_ACTION_TYPES = {
  UPLOAD_IMAGE_TO_BUDGET: "UPLOAD_IMAGE_TO_BUDGET",
  CONVENIENCE_FEE: "CONVENIENCE_FEE",
  DEPT_NUM: "DEPT_NUM",
  USE4WEEK: "USE4WEEK",

  LOGO: "LOGO",
  FOOTER_IMAGE: "FOOTER_IMAGE",
  TERMS_QUICK_PAY: "TERMS_QUICK_PAY",
  TERMS_PLAN: "TERMS_PLAN",
  TERMS_DISCOUNT: "TERMS_DISCOUNT",
  CURRENCY: "CURRENCY",
  PAYMENT_PROVIDER: "PAYMENT_PROVIDER",
  DRAWER_STATE: "DRAWER_STATE",
};
export const DOCUMENTS_ACTION_TYPES = {
  OPEN: "OPEN",
  DOCUMENT_TO_BE_VIEWED: "DOCUMENT_TO_BE_VIEWED",
};

export const HTML_ACTION_TYPES = {
  TERMS_QUICK_PAY: "TERMS_QUICK_PAY",
  TERMS_PLAN: "TERMS_PLAN",
  TERMS_DISCOUNT: "TERMS_DISCOUNT",

  VIEW_OFFER_TEXT: "VIEW_OFFER_TEXT",
  CREATE_PLAN_TEXT: "CREATE_PLAN_TEXT",
  CREATE_PLANTOC: "CREATE_PLANTOC",
  CREATE_BUDGET_TEXT: "CREATE_BUDGET_TEXT",
  VIEW_ACCOUNT_TEXT: "VIEW_ACCOUNT_TEXT",

  BURGER_BUTTON_ANIMATION: "BURGER_BUTTON_ANIMATION",
};
export const CAMERA_ACTION_TYPES = {
  OPEN_CAMERA: "OPEN_CAMERA",
  SET_IMAGE: "SET_IMAGE",
  ADD_CONFIRMED_IMAGE: "ADD_CONFIRMED_IMAGE",
  REMOVE_CONFIRMED_IMAGE: "REMOVE_CONFIRMED_IMAGE",
  RESET_CONFIRMET_IMAGES: "RESET_CONFIRMET_IMAGES",
};
export const RESET_STORE = "RESET_STORE";
