import * as ACTION_TYPES from "./action_types";

export const SUCCESS = {
  type: ACTION_TYPES.SUCCESS,
};

export const FAILURE = {
  type: ACTION_TYPES.FAILURE,
};

export const success = () => {
  return {
    type: ACTION_TYPES.SUCCESS,
  };
};

export const failure = () => {
  return {
    type: ACTION_TYPES.FAILURE,
  };
};

export const login_success = () => {
  return {
    type: ACTION_TYPES.LOGIN_SUCCESS,
  };
};

export const login_failure = () => {
  return {
    type: ACTION_TYPES.LOGIN_FAILURE,
  };
};

export const add_profile = (profile) => {
  return {
    type: ACTION_TYPES.ADD_PROFILE,
    payload: profile,
  };
};

export const set_Customer = (customer) => {
  return {
    type: ACTION_TYPES.SET_CUSTOMER,
    payload: customer,
  };
};

export const set_Callbacks = (callbacks) => {
  return {
    type: ACTION_TYPES.SET_CALLBACKS,
    payload: callbacks,
  };
};

export const set_Site = (site) => {
  return {
    type: ACTION_TYPES.SET_SITE,
    payload: site,
  };
};

export const set_Drawer = (state) => {
  return {
    type: ACTION_TYPES.SET_DRAWER,
    payload: state,
  };
};
export const set_Drawer1 = (state) => {
  return {
    type: ACTION_TYPES.SET_DRAWER,
    payload: state,
    num: 1,
  };
};
export const set_Drawer2 = (state) => {
  return {
    type: ACTION_TYPES.SET_DRAWER,
    payload: state,
    num: 2,
  };
};
export const set_selectedAccount = (state) => {
  return {
    type: ACTION_TYPES.SET_SELECTEDACCOUNT,
    payload: state,
  };
};
export const set_Drawer3 = (state) => {
  return {
    type: ACTION_TYPES.SET_DRAWER,
    payload: state,
    num: 3,
  };
};

export const set_Offer = (offer) => {
  return {
    type: ACTION_TYPES.SET_OFFER,
    payload: offer,
  };
};

export const set_Accounts = (accounts) => {
  return {
    type: ACTION_TYPES.SET_ACCOUNTS,
    payload: accounts,
  };
};
export const set_Registration_method = (payload) => {
  return {
    type: ACTION_TYPES.REGISTRTION_METHOD,
    payload: payload,
  };
};

export const remove_profile = () => {
  return {
    type: ACTION_TYPES.REMOVE_PROFILE,
  };
};

export const user_input_change = (text) => {
  return {
    type: ACTION_TYPES.USER_INPUT_CHANGE,
    payload: text,
  };
};

export const user_input_submit = (text) => {
  return {
    type: ACTION_TYPES.USER_INPUT_SUBMIT,
    payload: text,
  };
};
export const set_num_accounts = (text) => {
  return {
    type: ACTION_TYPES.SET_NUMACCOUNTS,
    payload: text,
  };
};
export const set_menu = (menu) => {
  return {
    type: ACTION_TYPES.SET_MENU,
    payload: menu,
  };
};
export const set_sessiontimeout = (time) => {
  return {
    type: ACTION_TYPES.SET_SESSIONTIMEOUT,
    payload: time,
  };
};
export const set_overalltimeout = (time) => {
  return {
    type: ACTION_TYPES.SET_OVERALLTIMEOUT,
    payload: time,
  };
};
export const set_startoveralltimeout = (time) => {
  return {
    type: ACTION_TYPES.SET_STARTOVERALLTIMEOUT,
    payload: time,
  };
};
export const set_iframeUrl = (url) => {
  return {
    type: ACTION_TYPES.IFRAME_URL,
    payload: url,
  };
};
export const set_PaymentStatusFrom = (status) => {
  return {
    type: ACTION_TYPES.PAYMENT_STATUS_FORM,
    payload: status,
  };
};
