import { GET_SITE_DETAILS_ACTION_TYPES } from "../redux-actions/redux_action_types";

export const initialState = {
  logoimageurl: "",
  footerImageUrl: "",
  currency: "",
  paymentProvider: "",
  deptphonenumber: "",
  uploadimagestobudget: false,
  convenienceFee: 0,
  use4week: false,
  drawers: {
    open: false,
    openSecond: false,
    openThird: false,
    lastOpenedDrawer: null, // add a new state variable to store the last opened drawer
  },
};

export const GetSiteDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SITE_DETAILS_ACTION_TYPES.USE4WEEK:
      return {
        ...state,
        use4week: action.payload,
      };
    case GET_SITE_DETAILS_ACTION_TYPES.CONVENIENCE_FEE:
      return {
        ...state,
        convenienceFee: action.payload,
      };
    case GET_SITE_DETAILS_ACTION_TYPES.UPLOAD_IMAGE_TO_BUDGET:
      return {
        ...state,
        uploadimagestobudget: action.payload,
      };
    case GET_SITE_DETAILS_ACTION_TYPES.LOGO:
      return {
        ...state,
        logoimageurl: action.payload,
      };
    case GET_SITE_DETAILS_ACTION_TYPES.DEPT_NUM:
      return {
        ...state,
        deptphonenumber: action.payload,
      };
    case GET_SITE_DETAILS_ACTION_TYPES.FOOTER_IMAGE:
      return {
        ...state,
        footerImageUrl: action.payload,
      };
    case GET_SITE_DETAILS_ACTION_TYPES.CURRENCY:
      return {
        ...state,
        currency: action.payload,
      };
    case GET_SITE_DETAILS_ACTION_TYPES.PAYMENT_PROVIDER:
      return {
        ...state,
        paymentProvider: action.payload,
      };
    case GET_SITE_DETAILS_ACTION_TYPES.DRAWER_STATE:
      return {
        ...state,
        drawers: action.payload,
      };
    default:
      return state;
  }
};
