import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useContext } from "react";
import { isMobile } from "react-device-detect";
import Context from "../../utils/context";
import * as URLS from "../../utils/XRSAPIs";
import HeaderSectionLoggedID from "../SectionsPage/Sections/HeaderSectionLoggedID.js";
import ViewOfferSection from "../SectionsPage/Sections/ViewOfferSection.js";
import { useSelector, useDispatch } from "react-redux";
import * as ACTIONS from "../../store/actions/actions";
import { ApiErrorHandler } from "utils/apiErrorHandler";
import { primaryColor } from "assets/jss/xrs.js";

import {
  apiActions,
  getSiteDetailsActions,
  htmlActions,
} from "../../store/redux/redux-actions/redux_actions";

let drawerWidth = 150;
if (isMobile) {
  drawerWidth = 0;
}
const useStyles2 = makeStyles((theme) => ({
  content: {
    paddingTop: 60,
    paddingBottom: 38,
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
}));
export default function ViewOfferPage(props) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  const dispatch = useDispatch();

  const context = useContext(Context);
  const classes2 = useStyles2();
  const [page, setPage] = React.useState(1);
  const stateSelectedAccount = useSelector(
    (state) => state.SelectedAccountsReducer.account
  );

  const token = sessionStorage.getItem("access_token");

  React.useEffect(() => {
    var account;
    var newDate = new Date();
    account = JSON.stringify({
      ITEMCODE: stateSelectedAccount.itemcode,
      RECNO: stateSelectedAccount.recno,
      STARTDATE: newDate,
      ENDDATE: newDate,
    });

    fetch(URLS.GetReturnAPIUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: account,
    })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          return res.json();
        } else {
          res.json().then((data) => {
            ApiErrorHandler("Get Return API", data, context);
          });
          throw Error("");
        }
      })

      .then((data) => {
        dispatch(ACTIONS.set_Offer(data));
      })
      .catch((error) => {
        if (error.message == "") {
        } else {
          ApiErrorHandler("Get Return API", error, context);
        }
      });
    fetch(URLS.GetWelcomeDetails, {
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          return res.json();
        } else {
          res.json().then((data) => {
            ApiErrorHandler("Get Welcome Details", data, context);
          });
          throw Error("");
        }
      })
      .then((data) => {
        dispatch(htmlActions.termsQuickPay(data.customer.termsquickpay));
        dispatch(htmlActions.termsPlan(data.customer.termsplan));
        dispatch(htmlActions.termsDiscount(data.customer.termsdiscount));

        dispatch(
          htmlActions.viewOfferText(data.activeAccounts[0].viewoffertext)
        );
        dispatch(
          htmlActions.createPlanText(data.activeAccounts[0].createplantext)
        );
        dispatch(
          htmlActions.createPlantoc(data.activeAccounts[0].createplantoc)
        );
        dispatch(
          htmlActions.createBudgetText(data.activeAccounts[0].createbudgettext)
        );
        dispatch(
          htmlActions.viewAccountText(data.activeAccounts[0].viewacccounttext)
        );

        dispatch(ACTIONS.set_Customer(data.customer));
        dispatch(ACTIONS.set_Accounts(data.activeAccounts));
        dispatch(ACTIONS.set_num_accounts(data.activeAccounts.length));

        dispatch(ACTIONS.set_Callbacks(data.callbackReasons));
      })
      .catch((error) => {
        if (error.message == "") {
        } else {
          ApiErrorHandler("Get Welcome Details", error, context);
        }
      });
  }, []);

  return (
    <div style={{ backgroundColor: primaryColor[14] }}>
      <HeaderSectionLoggedID
        page="viewoffer"
        title="View Offer"
      ></HeaderSectionLoggedID>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <main
            className={clsx(classes2.content, {
              [classes2.contentShift]: context.stateDrawer,
            })}
          >
            <ViewOfferSection></ViewOfferSection>
          </main>
        </GridItem>
      </GridContainer>
    </div>
  );
}
