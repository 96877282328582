import React, { useContext } from "react";
import classNames from "classnames";
import budgetPageStyle from "../../assets/jss/xrs/views/budgetPageStyle";
import { makeStyles } from "@material-ui/core/styles";
import landingPageStyle from "assets/jss/xrs/views/landingPageStyle.js";
import HeaderSectionLoggedID from "../SectionsPage/Sections/HeaderSectionLoggedID.js";
import BudgetSection from "../SectionsPage/Sections/BudgetSection.js";
import Context from "../../utils/context";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import clsx from "clsx";
import * as URLS from "../../utils/XRSAPIs";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Collapse } from "@material-ui/core";
import CustomCamera from "components/camera/Camera";
import PdfViewer from "components/PdfViewer/PdfViewer";
import FabViewDocument from "components/Fab/FabViewDocument";
import { primaryColor } from "assets/jss/xrs.js";

const useStyles = makeStyles(landingPageStyle);
let drawerWidth = 150;
if (isMobile) {
  drawerWidth = 0;
}
const useStyles2 = makeStyles(budgetPageStyle);

export default function BudgetPage(props) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);
  const viewDocument = useSelector(
    (state) => state.DocumentsReducer.documentToBeViewed
  );
  const context = useContext(Context);
  const open = useSelector((state) => state.CameraReducer.openFrom);
  const uploadimagestobudget = useSelector(
    (state) => state.GetSiteDetailsReducer.uploadimagestobudget
  );
  const classes = useStyles();
  const classes2 = useStyles2();
  const [page, setPage] = React.useState(1);

  const token = sessionStorage.getItem("access_token");

  return (
    <div style={{ backgroundColor: primaryColor[14] }}>
      <Collapse in={open === "" && viewDocument === "" ? true : false}>
        <HeaderSectionLoggedID
          page="budgetpage"
          title="Create Budget"
        ></HeaderSectionLoggedID>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <main
              className={clsx(classes2.content, {
                [classes2.contentShift]: context.stateDrawer,
              })}
            >
              <BudgetSection></BudgetSection>
            </main>
          </GridItem>
        </GridContainer>
      </Collapse>
      <Collapse in={open === "" ? false : true}>
        {open === "" || !isMobile || !uploadimagestobudget ? null : (
          <CustomCamera />
        )}
      </Collapse>
      <Collapse in={viewDocument === "" ? false : true}>
        {viewDocument === "" ? null : (
          <>
            <PdfViewer
              documentToShow={
                viewDocument.name === undefined
                  ? viewDocument.document.preview
                  : viewDocument.preview
              }
              pdf={viewDocument.name === undefined ? true : false}
            />{" "}
            <FabViewDocument></FabViewDocument>
          </>
        )}
      </Collapse>
    </div>
  );
}
