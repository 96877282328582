import React, { useState } from "react";
import { useWindowSize } from "components/camera/Camera";
import { isMobile } from "react-device-detect";

export default function PdfViewer({ pdf, documentToShow }) {
  const size = useWindowSize();

  React.useEffect(() => {
    if (pdf && isMobile) {
      window.open(documentToShow, "_blank");
    }
  }, [pdf]);
  if (pdf) {
    return (
      <embed
        height={size.height - 5}
        width={size.width}
        src={documentToShow}
      ></embed>
    );
  } else {
    return <img src={documentToShow} />;
  }
}
