import React, { useContext } from "react";
import classNames from "classnames";
import { primaryColor } from "assets/jss/xrs.js";

import { makeStyles } from "@material-ui/core/styles";
import landingPageStyle from "assets/jss/xrs/views/landingPageStyle.js";
import HeaderSectionLoggedID from "../SectionsPage/Sections/HeaderSectionLoggedID.js";
import ViewAccountSection from "../SectionsPage/Sections/ViewAccountSection.js";
import Context from "../../utils/context";
import clsx from "clsx";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
let drawerWidth = 150;
if (isMobile) {
  drawerWidth = 0;
}
const useStyles = makeStyles(landingPageStyle);
const useStyles2 = makeStyles((theme) => ({
  content: {
    paddingTop: 60,
    paddingBottom: 38,
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
}));

export default function ViewAccountPage(props) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  const context = useContext(Context);

  const classes = useStyles();
  const classes2 = useStyles2();
  const [page, setPage] = React.useState(1);

  return (
    <div style={{ backgroundColor: primaryColor[14] }}>
      <HeaderSectionLoggedID
        page="viewaccount"
        title="View Account"
      ></HeaderSectionLoggedID>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <main
            className={clsx(classes2.content, {
              [classes2.contentShift]: context.stateDrawer,
            })}
          >
            <ViewAccountSection></ViewAccountSection>
          </main>
        </GridItem>
      </GridContainer>
    </div>
  );
}
