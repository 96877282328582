/*eslint-disable*/ import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import landingPageStyle from "assets/jss/xrs/views/landingPageStyle.js";
import Button from "components/CustomButtons/Button.js";
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";

const useStyles = makeStyles(landingPageStyle);

export default function FooterSection(props) {
  const classes = useStyles();
  const footerImage = useSelector(
    (state) => state.GetSiteDetailsReducer.footerImageUrl
  );

  return (
    <div>
      <Footer
        theme="dark"
        content={
          <GridContainer className={classes.paperLeft}>
            <GridItem xs={12} sm={4} md={4}>
              <h3 className={classes.footerTitle}>
                About {ReactHtmlParser(props.companyname)}
              </h3>
              <p className={classes.footerParagraph}>
                {ReactHtmlParser(props.AboutUs)}
              </p>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <h3 className={classes.footerTitle}>{props.companyname}</h3>
              <p className={classes.footerParagraph}>{props.Address}</p>
              <p className={classes.footerParagraph}>{props.WorkingHours}</p>
              <p className={classes.footerParagraph}>
                Phone: {props.PhoneNumber}
              </p>
              <p className={classes.footerParagraph}>E-Mail: {props.Email}</p>
            </GridItem>
            <GridItem xs={12} sm={4} md={4} className={classes.CenterAlign}>
              <br></br>
              <br></br>
              {footerImage === "" ? null : (
                <img src={footerImage} alt="..." height="150" width="220" />
              )}
            </GridItem>
          </GridContainer>
        }
      />
    </div>
  );
}
