import {
  primaryColor,
  dangerColor,
  successColor,
  whiteColor,
  grayColor,
  defaultFont,
  blackColor,
} from "assets/jss/xrs.js";

const customInputStyle = {
  disabled: {
    "&:before": {
      backgroundColor: "white",
    },
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderBottomColor: primaryColor[0] + " !important",
      borderBottomWidth: "1px !important",
    },
    "&:after": {
      borderBottomColor: primaryColor[0],
    },
  },
  underlineError: {
    "&:after": {
      borderBottomColor: dangerColor[0],
    },
  },
  underlineSuccess: {
    "&:after": {
      borderBottomColor: successColor[0],
    },
  },
  helperTextRoot: {
    ...defaultFont,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "13.4px",
    marginTop: "8px" + " !important",
    width: "360px",
    lineHeight: "0",
    fontWeight: 300,
    zIndex: 1000,
    padding: 5,
    letterSpacing: "unset",
  },
  labelRoot: {
    ...defaultFont,
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: "18px",
    width: "400px",
    lineHeight: "0",
    fontWeight: 300,

    zIndex: 1000,
    padding: 5,
    letterSpacing: "unset",
    position: "relative",
  },
  labelRootWhite: {
    ...defaultFont,
    color: whiteColor + " !important",
    fontSize: "18px",
    position: "relative",

    width: "400px",
    lineHeight: "0",
    zIndex: 1000,
    padding: 5,
    letterSpacing: "unset",
  },
  labelRootError: {
    color: dangerColor[0] + " !important",
  },
  labelRootSuccess: {
    color: successColor[0] + " !important",
  },
  feedback: {
    position: "absolute",
    bottom: "4px",
    right: "0",
    zIndex: "2",
    display: "block",
    width: "24px",
    height: "24px",
    textAlign: "center",
    pointerEvents: "none",
  },
  formControl: {
    margin: "0 0 17px 0",
    paddingTop: "0px",
    position: "relative",
    "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
      color: grayColor[13],
    },
  },
  whiteUnderline: {
    "&:hover:not($disabled):before,&:before": {
      borderBottomColor: whiteColor,
    },
    "&:after": {
      borderBottomColor: whiteColor,
    },
  },
  input: {
    color: grayColor[13],
    // borderRadius: 3,
    // border: 1,
    padding: 10,

    // borderBottomColor: whiteColor,
    // borderColor: primaryColor[0],
    // borderStyle: "solid",
    height: "unset",
    "&,&::placeholder": {
      fontSize: "16px",
      fontFamily: '"Rubik", "Helvetica", "Arial", sans-serif',
      fontWeight: "500",
      color: blackColor,
      lineHeight: "1.42857",
      opacity: "1",
    },
    "&::placeholder": {
      color: grayColor[12],
      opacity: "0.2",
    },
  },

  fieldSet: {
    borderRadius: 3,
    border: 1,
    backgroundColor: "#f5f9ff",
    margin: 0 + " !important",
    borderColor: primaryColor[0],
    borderStyle: "solid",
  },
  currency: {
    paddingLeft: 10,
  },

  whiteInput: {
    "&,&::placeholder": {
      opacity: "1",
    },
    "&::placeholder": {
      color: whiteColor,
      opacity: "0.5",
    },
  },
};

export default customInputStyle;
