import imagesStyles from "../../imagesStyles";
import { primaryColor } from "assets/jss/xrs.js";

const faqSectionStyle = {
  ...imagesStyles,
  white: {
    color: "white",
    fontWeight: 500,
    paddingLeft: 20,
  },
  whiteRight: {
    color: "white",
    fontWeight: 500,
    textAlign: "right",
  },
  blueTitle: {
    color: primaryColor[20],
    fontWeight: 400,
    marginBottom: 0,
  },
  RightText: {
    marginBottom: 5,
  },
  Title: {
    fontWeight: 500,
    paddingTop: 30,
  },
  tableWrapper: {
    maxHeight: 270,
    overflow: "auto",
  },
  Wellcome: {
    fontWeight: 500,
  },
};
export default faqSectionStyle;
