import imagesStyles from "../../imagesStyles";
import { primaryColor } from "assets/jss/xrs.js";
const blueHeaderSectionStyle = {
  ...imagesStyles,
  white: {
    color: primaryColor[16],
    fontWeight: 500,
    paddingLeft: 10,
    fontFamily: primaryColor[17],
    fontSize: primaryColor[18],

    paddingTop: 5,
  },
  whiteCenter: {
    color: primaryColor[16],
    fontFamily: primaryColor[17],
    fontSize: primaryColor[18],
    fontWeight: 500,
    textAlign: "center",
    paddingRight: 10,
    paddingTop: 5,
    "@media (max-width: 600px)": {
      textAlign: "left",
      paddingLeft: 10,
    },
  },
  whiteRight: {
    color: primaryColor[16],
    fontFamily: primaryColor[17],
    fontSize: primaryColor[18],
    fontWeight: 500,
    textAlign: "right",
    paddingRight: 10,
    paddingTop: 5,
    "@media (max-width: 600px)": {
      textAlign: "left",

      paddingLeft: 10,
    },
  },
  blueTitle: {
    color: "#0065a4",
    fontWeight: 400,
  },
  Title: {
    fontWeight: 500,
    paddingTop: 40,
  },
  Wellcome: {
    fontWeight: 500,
  },
  CardStyle: {
    backgroundColor: primaryColor[15],
    padding: "3px",
  },
};

export default blueHeaderSectionStyle;
