import { HTML_ACTION_TYPES } from "../redux-actions/redux_action_types";

export const initialState = {
  termsQuickPay: "",
  termsPlan: "",
  termsDiscount: "",

  viewOfferText: "",
  createPlanText: "",
  createPlantoc: "",
  createBudgetText: "",
  viewAccountText: "",

  burgerButtonAnimation: true,
};

export const HtmlReducer = (state = initialState, action) => {
  switch (action.type) {
    case HTML_ACTION_TYPES.TERMS_QUICK_PAY:
      return {
        ...state,
        termsQuickPay: action.payload,
      };
    case HTML_ACTION_TYPES.TERMS_PLAN:
      return {
        ...state,
        termsPlan: action.payload,
      };
    case HTML_ACTION_TYPES.TERMS_DISCOUNT:
      return {
        ...state,
        termsDiscount: action.payload,
      };

    case HTML_ACTION_TYPES.VIEW_OFFER_TEXT:
      return {
        ...state,
        viewOfferText: action.payload,
      };
    case HTML_ACTION_TYPES.CREATE_PLAN_TEXT:
      return {
        ...state,
        createPlanText: action.payload,
      };
    case HTML_ACTION_TYPES.CREATE_PLANTOC:
      return {
        ...state,
        createPlantoc: action.payload,
      };
    case HTML_ACTION_TYPES.CREATE_BUDGET_TEXT:
      return {
        ...state,
        createBudgetText: action.payload,
      };
    case HTML_ACTION_TYPES.VIEW_ACCOUNT_TEXT:
      return {
        ...state,
        viewAccountText: action.payload,
      };
    case HTML_ACTION_TYPES.BURGER_BUTTON_ANIMATION:
      return {
        ...state,
        burgerButtonAnimation: action.payload,
      };

    default:
      return state;
  }
};
