import React, { useContext } from "react";
import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";
import landingPageStyle from "assets/jss/xrs/views/landingPageStyle.js";
import HeaderSectionLoggedID from "../SectionsPage/Sections/HeaderSectionLoggedID.js";
import FaqSection from "../SectionsPage/Sections/FaqSection.js";
import Context from "../../utils/context";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import MessagesSection from "../SectionsPage/Sections/MessagesSection.js";
import clsx from "clsx";
import * as URLS from "../../utils/XRSAPIs";
import { isMobile } from "react-device-detect";
import PdfViewer from "components/PdfViewer/PdfViewer";
import FabViewDocument from "components/Fab/FabViewDocument";
import { useDispatch, useSelector } from "react-redux";
import { primaryColor } from "assets/jss/xrs.js";

import { Collapse } from "@material-ui/core";
const useStyles = makeStyles(landingPageStyle);
let drawerWidth = 150;
if (isMobile) {
  drawerWidth = 0;
}
const useStyles2 = makeStyles((theme) => ({
  content: {
    paddingTop: 60,
    paddingBottom: 38,
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
}));

export default function MessagesPage(props) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  const context = useContext(Context);

  const classes = useStyles();
  const classes2 = useStyles2();
  const [page, setPage] = React.useState(1);
  const viewDocument = useSelector(
    (state) => state.DocumentsReducer.documentToBeViewed
  );
  return (
    <div style={{ backgroundColor: primaryColor[14] }}>
      <Collapse in={viewDocument !== "" ? false : true}>
        <HeaderSectionLoggedID
          page="Messages"
          title="Messages"
        ></HeaderSectionLoggedID>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <main
              className={clsx(classes2.content, {
                [classes2.contentShift]: context.stateDrawer,
              })}
            >
              <MessagesSection></MessagesSection>
            </main>
          </GridItem>
        </GridContainer>
      </Collapse>

      <Collapse in={viewDocument === "" ? false : true}>
        {viewDocument === "" ? null : (
          <>
            <PdfViewer
              documentToShow={viewDocument.preview}
              pdf={viewDocument.pdf}
            />{" "}
            <FabViewDocument></FabViewDocument>
          </>
        )}
      </Collapse>
    </div>
  );
}
