import { API_ACTION_TYPES } from "../redux-actions/redux_action_types";

export const initialState = {
  error: false,
  errorMessage: "",
  loading: false,
  code: 0,
};

export const ApiCallsReducer = (state = initialState, action) => {
  switch (action.type) {
    case API_ACTION_TYPES.ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        error: true,
        loading: false,
      };
    case API_ACTION_TYPES.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case API_ACTION_TYPES.CODE:
      return {
        ...state,
        code: action.payload,
      };
    case API_ACTION_TYPES.CLEAR:
      return {
        ...state,
        loading: false,
        errorMessage: "",
        error: false,
        code: 0,
      };
    default:
      return state;
  }
};
