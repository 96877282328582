const quickPaymentStyle = {
  white: {
    color: "white",
    fontWeight: 500,
    paddingLeft: 20,
  },
  visible: {
    display: "box",
  },
  hide: {
    display: "none",
  },
  whiteRight: {
    color: "white",
    fontWeight: 500,
    textAlign: "right",
  },
  blueTitle: {
    color: "#0065a4",
    fontWeight: 400,
  },
  Title: {
    textAlign: "center",
    fontWeight: 500,
    paddingTop: 30,
  },
  Wellcome: {
    fontWeight: 500,
  },
  blue: {
    backgroundColor: "#0065a4",
    borderRadius: 0,
    color: "#ffffff",
    padding: 10,
    textAlign: "center",
    fontWeight: 500,
    fontSize: 18,
  },
  green: {
    backgroundColor: "#ffffff;",
    borderRadius: 0,
    padding: 10,
    textAlign: "center",
    fontWeight: 500,
    fontSize: 19,
    paddingTop: 30,
    color: "#0065a4",
  },
  blueBox: {
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 10,
    borderRadius: 4,
  },
  DiscountBlue: {
    color: "#0065a4",
    textAlign: "center",
    paddingTop: 10,
    fontWeight: 500,
    fontSize: 16,
  },
  DiscountGreen: {
    color: "#4caf50",
    textAlign: "center",
    padding: 25,
    fontWeight: 500,
    fontSize: 16,
  },
  DiscountItem: {
    textAlign: "center",
    padding: 14,
    fontWeight: 500,
    margin: 1,
    fontSize: 16,
    backgroundColor: "#e6f2e6",
    Border: "none",
    borderRadius: 4,
  },
  DiscountItemRed: {
    textAlign: "center",
    padding: 14,
    fontWeight: 500,
    margin: 1,
    fontSize: 16,
    backgroundColor: "#ffe5e5",
    Border: "none",
    borderRadius: 4,
  },
  AmmountItemRed: {
    textAlign: "center",
    padding: 14,
    fontWeight: 500,
    margin: 1,
    fontSize: 40,
    backgroundColor: "#ffe5e5",
    Border: "none",
    Radiusradius: 4,
  },
  AmmountItem: {
    textAlign: "center",
    padding: 14,
    fontWeight: 500,
    margin: 1,
    fontSize: 40,
    backgroundColor: "#e6f2e6",
    Border: "none",
    Radiusradius: 4,
  },
  footer: {
    backgroundColor: "#4caf50",
    fontSize: 16,
    padding: 14,
    color: "#fff",
    borderRadius: 4,
    textAlign: "center",
  },
  footerRed: {
    backgroundColor: "#990000",
    fontSize: 16,
    padding: 14,
    color: "#fff",
    borderRadius: 4,
    textAlign: "center",
  },
  hidden: {
    display: "none",
  },
  textButton: {
    textTransform: "none",
    width: "100%",
  },
  box: {
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: "white",
  },
  middle: {
    width: "500px",
  },
};

export default quickPaymentStyle;
