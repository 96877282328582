import React from "react";
import styles from "assets/jss/xrs/components/fabStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import CommentOutlinedIcon from "@material-ui/icons/CommentOutlined";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import history from "utils/history.js";

const useStyles = makeStyles(styles);
export default function Fab(props) {
  const [openFab, setopenFab] = React.useState(false);

  const classes = useStyles();

  if (props.open == true) {
    return (
      <div className={classes.fabContainer}>
        <div
          onClick={() => setopenFab(!openFab)}
          className={classes.fab + " " + classes.fabIconHolder}
        >
          <i>
            <AddOutlinedIcon></AddOutlinedIcon>
          </i>
        </div>

        <ul
          className={classes.fabOptions}
          style={
            openFab
              ? { transform: "scale(1)", opacity: 1 }
              : { transform: "scale(0)", opacity: 0 }
          }
        >
          <li onClick={() => history.push("/documents")}>
            <span className={classes.fabLabel}>Documents</span>
            <div className={classes.fabIconHolder}>
              <i>
                <InsertDriveFileOutlinedIcon></InsertDriveFileOutlinedIcon>
              </i>
            </div>
          </li>
          <li>
            <span className={classes.fabLabel}>Chat</span>
            <div className={classes.fabIconHolder}>
              <i>
                <CommentOutlinedIcon></CommentOutlinedIcon>
              </i>
            </div>
          </li>
        </ul>
      </div>
    );
  } else {
    return <div></div>;
  }
}
