import history from "./history";
import React, { useContext } from "react";
import * as URLS from "./XRSAPIs";
import Moment from "react-moment";
import notificationsStyles from "assets/jss/xrs/views/componentsSections/notificationsStyles";
import Context from "./context";
import { ApiErrorHandler } from "utils/apiErrorHandler";
import { store } from "../store/redux/createReduxStore";
import { resetStore } from "../store/redux/redux-actions/redux_actions";

export default class Auth {
  userProfile = {};
  static context = Context;

  makeFormEncoder = (hash) => {
    return function(key) {
      return encodeURIComponent(key) + "=" + encodeURIComponent(hash[key]);
    };
  };

  getBearerToken = (username, password) => {
    var xhr = new XMLHttpRequest();
    var url = URLS.AuthenticationAPIUrl;

    var values = {
      grant_type: "password",
      username: username,
      password: password,
      validatePIN: "false",
      client_id: "PaymentPortal",
    };
    const pairs = Object.keys(values).map(this.makeFormEncoder(values));

    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        if (xhr.status == 200 || xhr.status == 201) {
          var json = JSON.parse(xhr.responseText);
          var access_token = json.access_token;
          var expires_in_seconds = json.expires_in;
          var timeoutDate;
          timeoutDate = new Date();
          timeoutDate.setSeconds(timeoutDate.getSeconds() + expires_in_seconds);
          sessionStorage.setItem("expiresAt", timeoutDate);
          sessionStorage.setItem("access_token", access_token);
        } else {
          ApiErrorHandler("Authentication", xhr, this.context);
        }
      }
    };
    var postValues = pairs.join("&");
    xhr.send(postValues);
  };

  Logon = (values, firstPosition, secondPosition, thirdPosition) => {
    var xhr = new XMLHttpRequest();
    var url = URLS.GetTokenURLAPI;

    var formValues = {
      grant_type: "password",
      username: values.username,
      password: values.password,
      validatePIN: "true",
      client_id: "PaymentPortal",
      firstValue: values.firstValue,
      firstPosition: firstPosition,
      secondValue: values.secondValue,
      secondPosition: secondPosition,
      thirdValue: values.thirdValue,
      thirdPosition: thirdPosition,
    };

    const pairs = Object.keys(formValues).map(this.makeFormEncoder(formValues));
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        if (xhr.status == 200 || xhr.status == 201) {
          var json = JSON.parse(xhr.responseText);
          var access_token = json.access_token;
          var refresh_token = json.refresh_token;
          var expires_in_seconds = json.expires_in;
          var timeoutDate;
          timeoutDate = new Date();
          timeoutDate.setSeconds(timeoutDate.getSeconds() + expires_in_seconds);
          sessionStorage.setItem("expiresAt", timeoutDate);
          sessionStorage.setItem("access_token", access_token);
          sessionStorage.setItem("refresh_token", refresh_token);

          history.push(`/welcome`);
        } else {
          ApiErrorHandler("Authentication", xhr, this.context);
        }
      }
    };
    var postValues = pairs.join("&");
    xhr.send(postValues);
  };

  RefreshToken = () => {
    var xhr = new XMLHttpRequest();
    var url = URLS.GetTokenURLAPI;
    let refresh_token = sessionStorage.getItem("refresh_token");
    var formValues = {
      grant_type: "refresh_token",
      client_id: "PaymentPortal",
      refresh_token: refresh_token,
    };
    const pairs = Object.keys(formValues).map(this.makeFormEncoder(formValues));
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        if (xhr.status == 200 || xhr.status == 201) {
          var json = JSON.parse(xhr.responseText);
          var access_token = json.access_token;
          var refresh_token = json.refresh_token;
          var expires_in_seconds = json.expires_in;
          var timeoutDate;
          timeoutDate = new Date();
          timeoutDate.setSeconds(timeoutDate.getSeconds() + expires_in_seconds);
          sessionStorage.setItem("expiresAt", timeoutDate);
          sessionStorage.setItem("access_token", access_token);
          sessionStorage.setItem("refresh_token", refresh_token);
        } else {
          ApiErrorHandler("Authentication", xhr, this.context);
        }
      }
    };
    var postValues = pairs.join("&");
    xhr.send(postValues);
  };

  handleAuth = () => {
    this.auth0.parseHash((err, authResult) => {
      if (authResult) {
        sessionStorage.setItem("access_token", authResult.accessToken);
        sessionStorage.setItem("id_token", authResult.idToken);

        let expiresAt = JSON.stringify(
          authResult.expiresIn * 1000 + new Date().getTime()
        );
        sessionStorage.setItem("expiresAt", expiresAt);

        this.getProfile();
        setTimeout(() => {
          history.replace("/authcheck");
        }, 600);
      } else {
      }
    });
  };

  getAccessToken = () => {
    if (sessionStorage.getItem("access_token")) {
      const accessToken = sessionStorage.getItem("access_token");
      return accessToken;
    } else {
      return null;
    }
  };

  getProfile = () => {
    let accessToken = this.getAccessToken();
    if (accessToken) {
      this.auth0.client.userInfo(accessToken, (err, profile) => {
        if (profile) {
          this.userProfile = { profile };
        }
      });
    }
  };

  logout = () => {
    sessionStorage.removeItem("access_token");

    //sessionStorage.removeItem("id_token");
    sessionStorage.removeItem("expiresAt");
    sessionStorage.removeItem("refresh_token");
    sessionStorage.removeItem("user");
    // ;
    store.dispatch(resetStore());
    history.replace("/landing-page");
    // history.push("/landing-page");
  };

  isAuthenticated = () => {
    let expiresAt = JSON.parse(sessionStorage.getItem("expiresAt"));

    return new Date().getTime() < expiresAt;
  };

  isAuthenticatedUser = () => {
    let expiresAt = new Date(sessionStorage.getItem("expiresAt"));
    let now = new Date();
    now.setSeconds(now.getSeconds());
    if (now.getTime() < expiresAt.getTime()) {
      return true;
    } else {
      return false;
    }
  };

  checkToken = () => {
    let expiresAt = new Date(sessionStorage.getItem("expiresAt"));
    let now = new Date();
    now.setSeconds(now.getSeconds() + 10);
    if (now.getTime() < expiresAt.getTime()) {
    } else {
      this.RefreshToken();
    }
  };

  getPinVerification = (values, record, pin) => {
    var xhr = new XMLHttpRequest();
    var url = URLS.GetVerifyAPIUrl;
    var id = record.id;
    var userName = values.regusername;
    var password = values.pass;
    var phoneConfirmed = record.phoneConfirmed;
    var reference = values.customernumber;
    var dateOfBirth = values.dob;
    var mobileNumber = values.phone;
    var customerRecordNo = record.customerRecordNo;
    var enteredPIN = pin;
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    var verifydata = JSON.stringify({
      id: id,
      userName: userName,
      phoneConfirmed: phoneConfirmed,
      reference: reference,
      dateOfBirth: dateOfBirth,
      mobileNumber: mobileNumber,
      customerRecordNo: customerRecordNo,
      pin: enteredPIN,
    });

    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        if (xhr.status == 200 || xhr.status == 201) {
          var xhrtoken = new XMLHttpRequest();
          var urltoken = URLS.GetTokenURLAPI;

          var values = {
            grant_type: "password",
            username: userName,
            password: password,
            validatePIN: "false",
            client_id: "PaymentPortal",
          };
          // eslint-disable-next-line no-case-declarations
          const pairs = Object.entries(values)
            .map(
              ([key, value]) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
            )
            .join("&");

          xhrtoken.open("POST", urltoken, true);
          xhrtoken.setRequestHeader(
            "Content-Type",
            "application/x-www-form-urlencoded"
          );

          xhrtoken.onreadystatechange = function() {
            if (xhrtoken.readyState === 4) {
              if (xhr.status == 200 || xhr.status == 201) {
                var json = JSON.parse(xhrtoken.responseText);
                var access_token = json.access_token;
                var expires_in_seconds = json.expires_in;
                var timeoutDate;
                timeoutDate = new Date();
                timeoutDate.setSeconds(
                  timeoutDate.getSeconds() + expires_in_seconds
                );
                sessionStorage.setItem("expiresAt", timeoutDate);
                sessionStorage.setItem("access_token", access_token);
                this.isUserAuthenticated = true;
                this.token = access_token;
                history.push(`/welcome`);
              } else {
                ApiErrorHandler("Authentication", xhr, this.context);
              }
            }
          };

          xhrtoken.send(pairs);
        } else {
          ApiErrorHandler("Authentication", xhr, this.context);
        }
      }
    };

    xhr.send(verifydata);
  };
}
